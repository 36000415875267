import record, {
  bool,
  enhancedType,
  integer,
  listOf,
  string,
  ensureType,
  typeWithDefault,
  failure,
} from 'cpcs-recordjs';
import I from 'immutable';

export const chainReducers =
  (...args) =>
  (state, action) =>
    args.reduce((prev, f) => f(prev, action), state);

export const uppercaseString = enhancedType({
  typeName: 'uppercaseString',
  parse(value = '') {
    return (value || '').toUpperCase();
  },
});

export const getEditableData = (editableFields, data) =>
  editableFields.reduce(
    (acc, name) => ({
      ...acc,
      ...(typeof name === 'string' ? { [name]: data[name] } : name(data)),
    }),
    {},
  );

export const Enterprise = record('Enterprise', {
  id: integer(),
  abvPurchases: integer(0),
  active: bool(false),
  enterpriseName: string(),
  includedAbv: integer(0),
  limitedAbv: bool(false),
  expirationDate: string(),
  artistReportCoverPageLogo: string(),
  artistReportPrimaryColor1: string(),
  artistReportSecondaryColor1: string(),
  artistReportSecondaryColor2: string(),
  artistReportSubtitle: string(),
  artistReportTextColor: string(),
  artistReportTitle: string(),
  artistReportHeaderLogo: string(),
  artistArtBnkCoverPageLogo: string(),
  artistArtBnkLastPageLogo: string(),
  artworkReportCoverPageLogo: string(),
  artworkReportPrimaryColor1: string(),
  artworkReportSecondaryColor1: string(),
  artworkReportSecondaryColor2: string(),
  artworkReportSubtitle: string(),
  artworkReportTextColor: string(),
  artworkArtBnkCoverPageLogo: string(),
  artworkArtBnkLastPageLogo: string(),
  artworkReportTitle: string(),
  artworkReportHeaderLogo: string(),
  portfolioClientLogoUrl: string(),
  portfolioReportTitle: string(),
  portfolioReportSubtitle: string(),
  portfolioPrimaryColor: string(),
  portfolioSecondaryColor1: string(),
  portfolioSecondaryColor2: string(),
  portfolioCoverPageTextColor: string(),
  portfolioArtdaiLogo: string(),
  portfolioArtdaiLogoColor: string(),
  portfolioArtdaiLastPageLogoColor: string(),
  portfolioReportHeaderLogoUrl: string(),
  enterpriseWebappClientLogo: string(),
  landingPageBanner: string(),
  landingPageHero: string(),
  enterpriseWebappClientLogoId: integer(),
  artworkReportCoverPageLogoId: integer(),
  artistReportCoverPageLogoId: integer(),
  portfolioClientLogoUrlId: integer(),
  artistReportHeaderLogoId: integer(),
  portfolioReportHeaderLogoUrlId: integer(),
  artworkReportHeaderLogoId: integer(),
});

export const Pagination = record('Pagination', {
  page: integer(1),
  pages: integer(0),
  pageSize: integer(100),
  total: integer(0),
});

export const JunkCharacter = record('JunkCharacter', {
  id: integer(),
  junkSymbol: string(''),
  correctLetter: string(''),
  createdDate: string(),
  updatedDate: string(),
});

export const Entity = record('Entity', {
  id: integer(),
  title: string(''),
  name: string(''),
});

export const Customer = record('Customer', {
  id: integer(),
  active: bool(false),
  alertsCount: integer(0),
  artsCount: integer(0),
  byMobile: bool(false),
  considerationsCount: integer(0),
  email: string(),
  emailVerified: bool(false),
  picture: string(),
  firstName: string(),
  lastName: string(),
  createdDate: string(),
  updatedDate: string(),
  lastLogin: string(),
  marketingEmailConsent: bool(false),
  sharedWithUser: integer(0),
  sharedByUser: integer(0),
  rtv: string('0.0'),
  country: Entity(),
  totalAbv: integer(0),
  abvPurchase: integer(0),
  occupations: listOf(Entity),
  subscription: string('−'),
  userStatus: string('−'),
  configuration: string('−'),
  stripePlan: string('−'),
  renewalDate: string(),
  abvPurchases: integer(0),
  stripeUserUrl: string('−'),
  locked: bool(true),
  freeRtvPurchaseCount: integer(0),
  trialRemaining: integer(0),
  enterprise: Enterprise,
  seatType: string(),
});

export const History = record('History', {
  username: string(''),
  dateCreated: string(''),
  modelId: integer(),
  modelName: string(''),
  action: listOf(string),
});

export const Tooltip = record('Tooltip', {
  x: integer(),
  y: integer(),
  text: string(''),
  translate: bool(false),
});

export const idAndTimeStamp = {
  id: integer(),
  createdDate: string(),
  updatedDate: string(),
};

// Spider Runner
export const Runner = record('Runner', {
  ...idAndTimeStamp,
  artifactName: string(),
  artifactPackagesPath: string(),
  artifactPythonVersion: string(),
  dateStatusUpdate: string(),
  domain: string(),
  scrapydHost: string(),
  spiderName: string(),
  startDate: string(),
  type: string('auction'),
  useCrawlItem: bool(false),
  version: string(),
  versionValue: string(),
  wfAcceptance: string('DISABLED'),
});

// Spider Session
export const Session = record('Session', {
  closeDate: string(),
  createdCountLots: integer(0),
  id: integer(0),
  jobId: string(),
  openDate: string(),
  pendingDate: string(),
  spiderName: string(),
  totalCountLots: integer(0),
  updatedCountLots: integer(0),
  wfAcceptance: string('CLOSED'),
});

const commonDictionaryFields = {
  ...idAndTimeStamp,
  acceptedDate: string(),
  isDelete: bool(false),
};

const commonFields = {
  ...commonDictionaryFields,
  locked: bool(true),
  history: History(undefined),
  sourceType: string(),
  notes: string(),
  wfAcceptance: string('DRAFT'),
  usernameLastUpdate: string(),
};

const authorFields = {
  id: integer(),
  fullName: uppercaseString(),
  residences: listOf(integer),
  birthDate: integer(),
  deathDate: integer(),
  countScrapedArts: integer(0),
  wfAcceptance: string('DRAFT'),
};

export const Stats = record('stats', {
  new: integer(0),
  draft: integer(0),
  valid: integer(0),
  accepted: integer(0),
  ignore: integer(0),
  display: integer(0),
  // lots
  excluded: integer(0),
  ready: integer(0),
});

export const Author = record('Author', authorFields);

export const AuctionSale = record('AuctionSale', {
  id: integer(0),
  name: string(''),
  count: integer(0),
});

export const IndexData = record('IndexData', {
  date: string(),
  value: string(),
});
export const SmcTag = record('SmcTag', {
  ...idAndTimeStamp,
  smcTag: string(''),
  fullName: string(''),
  lowCarValue: string(),
  carValue: string(),
  highCarValue: string(),
  defaultCarField: string(),

  mgvEnabled: bool(),
  period: string(''),
  indexData: listOf(IndexData),
  csvFile: string(''),
});

export const Artist = record('Artist', {
  ...authorFields,
  namePrefix: string(''),
  firstName: string(''),
  middleName: string(''),
  lastName: string(''),
  nameSuffix: string(''),
  carTag: listOf(SmcTag),
  secondaryCarTag: listOf(SmcTag),
  mergeWith: integer(),
  biography: string(),
  uuid: string(),
  wfAcceptance: string('DRAFT'),
  showRtv: bool(false),
  countKnownArts: integer(0),
  countTradedArtsInDb: integer(0),
  countSoldArtsInDb: integer(0),
  auctionCountList: listOf(AuctionSale),
  customersAoCount: integer(0),
  rtvCount: integer(0),
  children: listOf(Author),
  hasChildren: bool(false),
  rtvCalc: string('Status: UNKNOWN'),
  carValue: string(),
  lowCarValue: string(),
  decayCoefficient: string(),
  highCarValue: string(),
  banner343x120: string(),
  banner720x120: string(),
  banner970x120: string(),
  bannerDisplayDateFrom: string(),
  bannerDisplayDateTo: string(),
  bannerUrl: string(),
  searchCount: integer(0),
  alertsCount: integer(0),
  defaultCarField: string,
  ...commonFields,
});

export const Portfolio = record('Portfolio', {
  portfolioId: integer(),
  enterprise: string(""),
  userName: string(""),
  portfolioTitle: string(""),
  id: integer(),
  artworkTitle: string(""),
  artistName: string(""),
  acquisitionDate: string(""),
  acquisitionValue: integer(),
  projectedValue: integer(),
  ltmGain: integer(),
  ltmGainPercentage: string(),
  totalGain: integer(),
  totalGainPercentage: string(),
  status: string(""),
  dateCreated: string(""),
  dateUpdated: string(""),
  // userId: integer(),
})

export const Image = record('Image', {
  id: integer(),
  title: string(''),
  imageFile: string(''),
});

const Provenance = record('Provenance', {
  id: integer(),
  uuid: string(),
  art: integer(),
  owner: string(),
  yearFrom: integer(),
  yearTo: integer(),
  city: string(),
  country: integer(),
});

const Literature = record('Literature', {
  id: integer(),
  name: string(),
  artId: integer(),
});

const Exhibitions = record('Exhibitions', {
  id: integer(),
  name: string(),
  artId: integer(),
});

export const Alert = record('Alert', {
  id: integer(),
  artist: Artist,
  count: integer(1),
});

const indexedSetOfAny = (type, keySelector) =>
  typeWithDefault(I.Map(), {
    itemType: ensureType(type),
    typeName: `{${type.typeName}}`,
    parse(value, path = []) {
      const isNothing = (x) => typeof x === 'undefined' || x === null;
      if (isNothing(value)) {
        return undefined;
      }
      if (I.Map.isMap(value)) {
        value = value.valueSeq();
      }

      let isArrayLike =
        Array.isArray(value) || I.List.isList(value) || I.Set.isSet(value);
      const isCollection = (v) => v && v.toJS && v.valueSeq;
      const isParsableObject =
        !isArrayLike &&
        value &&
        typeof value === 'object' &&
        !isCollection(value);

      if (!isArrayLike && isParsableObject) {
        isArrayLike = true;
        value = Object.values(value);
      }
      if (isArrayLike) {
        const ps = path.concat(['[]']);
        return value.reduce((acc, v) => {
          const res = type.parse(v, ps);
          return acc.set(keySelector(res), res);
        }, I.Map());
      } else {
        return failure(path, `{${type.typeName}}`, value);
      }
    },
    operations: {
      put: (attr) => (x) => attr.updateBy('set', keySelector(x), x),
      remove: (attr) => (x) => attr.updateBy('delete', keySelector(x)),
      updateById: (attr) => (id, f) => attr.update((m) => m.update(id, f)),
    },
  });

const CarLot = record('LotCar', {
  auctionDate: string(),
  auctionId: integer(0),
  labelNumber: integer(0),
  auctionPriceUsd: string(),
  auctionInterval: string(),
  auctionCar: string(),
  highEstimateUsdPlusBp: string(),
  medianEstimateUsdPlusBp: string(),
  lowEstimateUsdPlusBp: string(),
  estimatePriceStartUsd: integer(0),
  estimatePriceEndUsd: integer(0),
});

export const Artwork = record('Artwork', {
  id: integer(0),
  title: string(''),
  artist: Artist(),
  category: integer(),
  medium: string(''),
  mediumRaw: string(''),
  medium3d: listOf(integer),
  catalogRaisonne: string(''),
  lastExhibition: string(),
  lastLiterature: string(),
  lastProvenance: string(),
  subjects: listOf(integer),
  surfaces: listOf(integer),
  surfaceValues: listOf(integer),
  substrates: listOf(integer),
  substrateValues: listOf(integer),
  primarySurfaceRank: integer(),
  primarySubstrateRank: integer(),
  isSignature: string('No'),
  yearCreatedFrom: integer(),
  yearCreatedTo: integer(),
  physicalSizeRaw: string(),
  physicalSizeWidth: string(),
  physicalSizeHeight: string(),
  physicalSizeDepth: string(),
  physicalSizeUnits: string(),
  artistId: integer(),
  artImages: listOf(Image),
  images: listOf(integer()),
  defaultArtImage: integer(),
  defaultImageUrl: string(),
  literature: listOf(Literature),
  exhibitions: listOf(Exhibitions),
  outliers: string(),
  ...commonFields,
  provenances: listOf(Provenance),
  signature: string(),
  notes: string(),
  spLastUsd: string(),
  epStartLastUsd: string(),
  epEndLastUsd: string(),
  lastLotCurrency: integer(),
  auctionHouseLast: integer(),
  lastSaleNumber: string(),
  lastLotNumber: string(),
  auctionDateLast: string(),
  spLast: string(),
  estimatePriceStartLast: string(),
  estimatePriceEndLast: string(),
  rtvLast: string(),
  area: string(),
  costPerSquareWithCar: string(),
  lastLotSoldPriceUsd: string(),
  lastLotSoldAuctionDate: string(),
  lastSoldPriceUsdWithCar: string(),
  countOfLots: integer(0),
  hasRtvClusters: bool(false),
  conceptionDateFrom: integer(),
  conceptionDateTo: integer(),
  inscription: string(),
  studio: string(),
  authenticityLetter: string(),
  foundry: string(),
  stamps: string(),
  edition: string(),
  sheetSizeRaw: string(),
  sheetSizeWidth: string(),
  sheetSizeHeight: string(),
  sheetSizeDepth: string(),
  sheetSizeUnits: string(),
  printer: string(),
  publisher: string(),
  plate: string(),
  numberedNo: integer(),
  numberedOf: integer(),
  editionSize: integer(),
  artistProof: integer(),
  pressProof: integer(),
  rtvDate: string(),
  aeRtv: string(),
  defaultRtv: string(),
  carValue: string(),
  currentCarValue: string(),
  artImageUrl: string(),
  abvQualityBenchmark: string(),
  customer: Customer(),
  customers: integer(0),
  abvLastSoldPrice: string(),
  lastAlertAbv: string(),
  abvMetricsManifold: bool(),
  abvMetricsMediums: bool(),
  abvMetricsArea: bool(),
  abvMetricsPpsi: bool(),
  lastGuarantee: bool(),
  hasLots: bool(false),
  hasSimilarImages: bool(),
  hasRepeatSales: bool(),
  firstAcceptedSoldPriceUsd: string(),
  firstAcceptedLotDate: string(),
  auctionCar: string(),
  auctionInterval: string(),
  lowCarAbv: string(),
  middleCarAbv: string(),
  highCarAbv: string(),
  firstAuctionEstimateUsd: string(),
  firstAuctionPriceUsd: string(),
  firstAuctionDate: string(),
  firstAuctionHouse: string(),
  lowSoldPriceUsdWithCar: string(),
  middleSoldPriceUsdWithCar: string(),
  highSoldPriceUsdWithCar: string(),
  lastAcceptedAndDisplaySoldPriceUsd: string(),
  lastAcceptedAndDisplayLotDate: string(),
  lastAcceptedAndDisplaySoldPriceUsdWithCar: string(),
  clusterNumber: integer(),
  alertAbv: string(),
  alertHighAbv: string(),
  alertMiddleAbv: string(),
  alertLowAbv: string(),
  highEstimate: string(),
  middleEstimate: string(),
  lowEstimate: string(),
  auctionCarLotList: indexedSetOfAny(CarLot, CarLot.$labelNumber),
  useCarPrice: bool(),
});

const LotUrl = record('LotUrl', {
  id: integer(),
  itemUrl: string(),
});

export const Lot = record('Lot', {
  uuid: string(),
  id: integer(0),
  art: Artwork(),
  // but there are excess fields: [locked, history],
  ...commonFields,
  completeness: string(),
  artId: integer(0),
  auctionId: integer(0),
  // what type is it?
  // auctionSale: null,
  auctionDate: string(),
  saleNumber: string(),
  // what type is it?
  // lotNumber: null
  lotName: string(),
  lotNumber: string(),
  isDueDiligenceRisk: bool(false),
  isPastAuction: bool(false),
  // what type is it?
  // provenance: null
  estimatePriceStartUsd: integer(),
  estimatePriceEndUsd: integer(),
  condition: listOf(integer),
  notes: string(),
  imageFile: string(),
  imageFileUrl: string(),
  defaultArtImage: integer(),
  images: listOf(integer),
  artImages: listOf(Image),
  lotUrls: listOf(LotUrl),
  auctionTitle: string(),
  auctionLocation: string(),
  auctionSaleUrl: string(),
  auctionStartDate: string(),
  // auctionEndDate: string(),
  auctionStartTime: string(),
  auctionEndTime: string(),
  soldPrice: integer(),
  isSoldPriceUpdated: bool(false),
  soldPriceRaw: string(),
  soldPriceUnitRaw: string(),
  soldPriceUsd: string(),
  estimatePriceRaw: string(),
  estimatePriceStart: integer(),
  estimatePriceEnd: integer(),
  currency: integer(),
  provenance: string(),
  conditionRaw: string(),
  alertAbv: string(),
  guaranteeLocked: bool(true),
  guarantee: bool(),
  privateSale: bool(),
  highEstimateUsdPlusBp: string(),
  medianEstimateUsdPlusBp: string(),
  lowEstimateUsdPlusBp: string(),
  auctionCar: string,
  auctionInterval: string,
  catalogRaisonne: string(''),
  literature: listOf(Literature),
  exhibition: listOf(Exhibitions),
  hammerPrice:integer()
});




export const Auction = record('Auction', {
  ...commonDictionaryFields,
  name: string(),
  country: integer(),
  city: string(),
  isPrivate: bool(false),
  lotsCount: integer(0),
});

export const Sale = record('Sale', {
  ...commonDictionaryFields,
  auction: integer(),
  saleNumber: string(),
  title: string(),
});

const MediumValue = record('SubstrateValue', {
  id: integer(),
  value: string(),
});

const ArtworkMini = record('ArtworkMini', {
  id: integer(),
  title: string(''),
  pictures: listOf(string),
  artist: Artist(),
});

export const Purchase = record('Purchase', {
  id: integer(),
  rtvPrice: integer(0),
  rtvCurrency: string('USD'),
  purchasedDate: string(),
  artwork: ArtworkMini(),
  current: bool(false),
  abvLoading: bool(false),
});

const mediumFields = {
  ...commonDictionaryFields,
  description: string(''),
  order: integer(0),
  rank: integer(0),
  values: listOf(MediumValue),
  defaultValueId: integer(0),
};

export const Surface = record('Surface', mediumFields);

export const Substrate = record('Substrate', mediumFields);

export const Subject = record('Subject', {
  id: integer(),
  title: string(''),
  level: string('SECONDARY'),
});

export const Medium = record('Medium', {
  ...commonDictionaryFields,
  value: string(''),
  order: integer(0),
  rank: integer(0),
});

export const Coupon = record('Coupon', {
  id: integer(),
  title: string(''),
  expireOn: string(),
  maximumRedemption: integer(0),
  active: bool(false),
});

export const Cluster = record('Cluster', {
  artId: integer(),
  url: string(),
  cluster: integer(),
  auctionDate: string(),
  baseSoldPrice: string(),
  cpiNormalBaseSoldPrice: string(),
  area: string(),
  ppsi: string(),
  artClusterType: string(),
  mediumsSurfaces: string(),
  mediumsSubstrates: string(),
  methodInfo: string(),
  primarySurfaceRank: integer(),
  primarySubstrateRank: integer(),
  outliersOutlier: string(),
  unitFilterPpsiFilterPpsi: string(),
});

// Spider Art
export const Scrapped = record('Scrapped', {
  ...idAndTimeStamp,
  item: string(),
  art: Artwork,
  artist: Artist,
  auction: Auction,
  auctionLot: Lot,
  exceptionText: string(),
  isCorrect: bool(false),
  spiderItem: integer(),
  version: string(),
});

// Spider Item
export const Item = record('Item', {
  ...idAndTimeStamp,
  itemUrl: string(),
  type: string(),
  urlKey: string(),
  itemListCount: integer(0),
  version: string(),
  artItems: listOf(Scrapped),
});
