import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { Link } from 'react-router-dom';
import Image from 'components/SmartImage';
import ArtworkId from 'fields/ArtworkIdSearchField';
import { dateFieldProps, numberFieldProps } from 'lib/helpers';
import { PureInput } from 'filters/common';
import { Field, FieldArray } from 'redux-form';
import I from 'immutable';
import IndicatorLink from 'components/IndicatorLink';

import { defineColumns } from 'components/PageTable/defineColumns';
import { dictList, dict, artistNormalize } from 'lib/serialize';

import { acceptanceFields } from 'domain/lot';
import { lnk } from 'lib/routes';
import { uploadImageAction } from 'domain/common';
import { lot as currentLot } from 'domain/lotList';
import { getCarLotFieldsConfig } from 'fields/carLotFields';

import {
  NotesTextArea,
  ImageDropField,
  commonFields,
  connectAcceptance,
  toPrice,
  toPriceRange,
  toNumber, yesNoDash,
  DatePickerDouble,
  timeInput,
} from 'fields/common';

import { CurrencyAutocomplete, ConditionsAutocomplete, CategoryAutoComplete } from './Autocomplete';

import rules from 'components/form/validation';
import LotLinks from './LotLinks';
import NewPrice from './NewPrice';

import Guarantee from "fields/Guarantee";
import { PureTextArea, sheet as textAreaSheet } from 'components/form/element/TextArea';
import injectSheet from 'lib/sheet';
import Literature from 'fields/Literature';
import Exhibitions from 'fields/Exhibitions';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const TextArea = injectSheet({ ...textAreaSheet, wrapper: { width: '100%', height: 60 } })(PureTextArea);
const LiteratureFn = (props) => <FieldArray name="literature" component={Literature} fieldProps={props} />;
const ExhibitionsFn = (props) => <FieldArray name="exhibition" component={Exhibitions} fieldProps={props} />;
const mapArrayValue = (value = []) => value.map(({ name }) => name).join(', ');
const getArrayValue = ({ value }) => mapArrayValue(value);
const urlField = (props) => <FieldArray name="lotUrls" component={LotLinks} fieldProps={props} />;

const lotLinks = ({ value, tableName }) => {
  if (value.size === 0) return '−';
  const httpReg = /^https?:\/\/*/;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
      { value
        .reverse()
        .toJS()
        .slice(0, tableName ? 3 : value.size )
        .map(({ id, itemUrl }) => <a
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '20px' }}
          key={id}
          href={httpReg.test(itemUrl) ? itemUrl : 'http://' + itemUrl}
          children={itemUrl}
          target="_blank"
          rel="noopener noreferrer"
        />) }
      { tableName && value.size > 3 && <div>and <b>{value.size - 3}</b> other link(s)</div> }
    </div>
  );
};

lotLinks.propTypes = {
  value: PropTypes.instanceOf(I.List),
  tableName: PropTypes.string,
};

const LotLink = ({
  value: lotId,
  rowData: { artId: artworkId },
  classes: { link },
}) => artworkId ? <Link className={link} to={lnk('artwork', { artworkId })}>{lotId}</Link> : `${lotId}`;

LotLink.propTypes = {
  value: PropTypes.number.isRequired,
  rowData: PropTypes.shape({
    artId: PropTypes.number.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
};

const LotLinkImage = ({
  value,
  rowData: { artId: artworkId },
  classes: { link },
}) => {
  const img = <Image
    src={value}
    params={{ width: 80, height: 80 }}
  />;
  if (artworkId) {
    return (
      <Link
        className={link}
        to={lnk('artwork', { artworkId })}
        children={img}
      />
    );
  }
  return img;
};

const LotImageDisplay = ({ rowData: { artImages, defaultArtImage } }) => {
  const src = artImages.getIn([ artImages.findIndex(({ id }) => id === defaultArtImage), 'imageFile' ]);
  return <Image
    src={src}
    fit={{ maxWidth: 300, maxHeight: 300 }}
    originalLink
    params={{ width: 300, height: 300 }}
    container="img"
  />;
};

LotImageDisplay.propTypes = {
  rowData: PropTypes.shape({
    defaultArtImage: PropTypes.number,
    artImages: PropTypes.instanceOf(I.List),
  }),
};

LotLinkImage.propTypes = {
  value: PropTypes.string,
  rowData: PropTypes.shape({
    artId: PropTypes.number.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
};

// TODO numeric format/normalize
const EstimatePriceFunc = () => [
  <Field {...numberFieldProps} key="estimatePriceStart" name="estimatePriceStart" component={PureInput} placeholder="min" />,
  <div key="separator" style={{ lineHeight: '32px', padding: '0 5px' }}>-</div>,
  <Field {...numberFieldProps} key="estimatePriceEnd" name="estimatePriceEnd" component={PureInput} placeholder="max" />,
];

const LotImage = connect({
  onUpload: uploadImageAction,
  object: currentLot,
})(ImageDropField);

const ArtworkTitle = ({ rowData: { art: { title, wfAcceptance } } }) =>
  <IndicatorLink
    status={wfAcceptance}
    children={title}
  />;

const ArtistLink = ({ rowData: { art: { artist } }, columnDef: { defaultValue } }) => artist.id ?
  <IndicatorLink
    to={lnk('artist', { artistId: artist.id })}
    status={artist.wfAcceptance}
    children={artistNormalize(artist)}
  /> : defaultValue;

ArtistLink.propTypes = {
  columnDef: PropTypes.shape({
    defaultValue: PropTypes.node,
  }).isRequired,
  rowData: PropTypes.shape({
  }).isRequired,
};

const getNewPricesParams = ({ value, rowData: { id, art: { title, artist } } }) => ({
  value,
  lotId: id,
  title: `${artistNormalize(artist)} "${title}"`,
});
const AuctionSaleDate = () => [
  <Field
    key="auctionStartDate"
    name="auctionStartDate"
    component={DatePickerDouble}
    { ...dateFieldProps }
  />,
  <Field
    key="auctionDate"
    name="auctionDate"
    component={DatePickerDouble}
    validate={[ rules.auctionDate ]}
    { ...dateFieldProps }
  />,
  <div key="label" style={{ lineHeight: '32px' }}><FormattedMessage id="ifOnline"/></div>,
];
const AuctionSaleTime = () => [
  <Field
    key="auctionStartTime"
    name="auctionStartTime"
    placeholder="auctionStartTime"
    component={timeInput}
    type="text"
    />,
    <span style={{ padding: '0px 5px' }}></span>,
    <Field
    key="auctionEndTime"
    name="auctionEndTime"
    placeholder="auctionEndTime"
    component={timeInput}
    type="text"
  />,
  // <div key="label" style={{ lineHeight: '32px' }}><FormattedMessage id="If Online"/></div>,
];

const GroupAuctionTime = (auctionStartTime, auctionEndTime) =>{
  if (auctionStartTime && auctionEndTime) {
    return `${auctionStartTime} - ${auctionEndTime}`;
  }
  return auctionStartTime ? `${auctionStartTime}` : auctionEndTime ? `${auctionEndTime}` : '−';
}
const GroupAuctionDate = (auctionStartDate, auctionEndDate) => {
  const formatDate = (date) => moment(date).format('MM/DD/YYYY');
  if (auctionStartDate && auctionEndDate) {
    return `${formatDate(auctionStartDate)} - ${formatDate(auctionEndDate)}`;
  } 
  return auctionStartDate ? formatDate(auctionStartDate) : auctionEndDate ? formatDate(auctionEndDate) : '−';
}
const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: LotLink,
    parser: ({ value }) => value === undefined ? '' : value,
  },
  defaultArtImage: {
    inlineEdit: true,
    hiddenForTable: true,
    getValue: LotImageDisplay,
    formFieldRenderer: () => <LotImage/>,
  },
  title: {
    title: 'artId',
    sortable: true,
    sortByName: 'artId',
    fieldPath: ['art','title'],
    getValue: ArtworkTitle,
  },
  artist: {
    sortable: true,
    sortByName: 'artId__artistId',
    getValue: ArtistLink,
    defaultValue: '−',
  },
  conditionRaw: {
    hiddenForTable: true,
    component: NotesTextArea,
  },
  condition: {
    component: ConditionsAutocomplete,
    getValue: dictList(),
    defaultValue: '−',
    fieldProps: { isMulti: true },
  },
  imageFileUrl: { getValue: LotLinkImage },
  auctionTitle: { className: 'td200', sortable: true, parser: ({ value }) => value === undefined ? '−' : value, },
  auctionLocation: { className: 'td200', sortable: true, parser: ({ value }) => value === undefined ? '−' : value, },
  auctionSaleUrl: { className: 'td200', sortable: true, parser: ({ value }) => value === undefined ? '−' : value, },
  auctionSaleDate: { formFieldRenderer: AuctionSaleDate, hiddenForTable: true,
    getValue: ({ rowData }) => GroupAuctionDate(rowData?.auctionStartDate, rowData?.auctionDate)
  },
  auctionSaleTime: { formFieldRenderer: AuctionSaleTime, hiddenForTable: true,
    getValue: ({ rowData }) => GroupAuctionTime(rowData?.auctionStartTime, rowData?.auctionEndTime)
  },
  saleNumber: {
    inlineEdit: true,
    sortable: true,
    sortByName: 'saleNumberValue',
  },
  lotNumber: {
    inlineEdit: true,
    sortable: true,
    sortByName: 'lotNumberValue',
  },
  estimatePriceRaw: {},
  soldPrice: {
    inlineEdit: true,
    sortable: true,
    getValue: toPrice,
    fieldProps: {
      ...numberFieldProps,
    },
  },
  soldPriceRaw: {
    sortable: true,
  },
  soldPriceUnitRaw: {
    sortable: true,
  },
  isSoldPriceUpdated: {
    sortable: true,
    getValue: (params) => <NewPrice {...getNewPricesParams(params)} />,
  },
  soldPriceUsd: { sortable: true, getValue: toNumber },
  currency: {
    inlineEdit: true,
    sortable: true,
    getValue: dict('alpha3'),
    component: CurrencyAutocomplete,
    fieldProps: {
      validate: [ rules.lotCurrency ],
    },
  },
  estimatePrice: {
    getValue: toPriceRange('estimatePriceStart', 'estimatePriceEnd'),
    defaultValue: '−',
    formFieldRenderer: EstimatePriceFunc,
  },
  estimatePriceStart: {
    inlineEdit: true,
    sortable: true,
    getValue: toPrice,
    fieldProps: {
      ...numberFieldProps,
    },
  },
  estimatePriceEnd: {
    inlineEdit: true,
    sortable: true,
    getValue: toPrice,
    fieldProps: {
      ...numberFieldProps,
    },
  },
  estimatePriceStartUsd: { sortable: true, getValue: toNumber },
  estimatePriceEndUsd: { sortable: true, getValue: toNumber },
  provenance: {
    defaultValue: '−',
    getValue: ({ value }) => value ? <span dangerouslySetInnerHTML={{ __html: value.replaceAll('\n', '<br/>') }}/> : '−',
    component: NotesTextArea,
  },
  artId: {
    title: 'aoId',
    component: ArtworkId,
    fieldProps: { validate: [ rules.required ] },
    sortable: true,
    sortByName: 'artIdId',
  },
  lotUrls: {
    required: true,
    getValue: lotLinks,
    formFieldRenderer: urlField,
  },
  artistIdWfAcceptance: {
    sortByName: 'artId__artistId__wfAcceptance',
    fieldPath: ['art', 'artist', 'wfAcceptance'],
    sortable: true,
  },
  artIdWfAcceptance: {
    sortByName: 'artId__wfAcceptance',
    fieldPath: ['art', 'wfAcceptance'],
    sortable: true,
  },
  usernameLastUpdate: { sortable: true, getValue: ({ value }) => value || '−' },
  alertAbv: {
    className: 'price',
    sortable: true,
    getValue: ({ value }) => value ? `$${ toNumber({ value }) }` : '−',
  },
  guarantee: {
    formFieldRenderer: () => <Guarantee/>,
    getValue: ({ value }) => !!value ? 'Yes' : 'No',
    className: 'number',
    sortable: true,
  },
  hasSimilarImages: {
    sortable: true,
    sortByName: 'artId__hasSimilarImages',
    className: 'number',
    getValue: ({ rowData }) => rowData?.art?.hasSimilarImages ? 'Yes' : 'No',
  },
  hasRepeatSales: {
    sortable: true,
    sortByName: 'artId__hasRepeatSales',
    className: 'number',
    getValue: ({ rowData }) => rowData?.art?.hasRepeatSales ? 'Yes' : 'No',
  },
  artistShowRtv: {
    sortable: true,
    sortByName: 'artId__artistId__showRtv',
    className: 'number',
    getValue: ({ rowData }) => rowData?.art?.artist.showRtv ? 'Yes' : 'No',
  },
  privateSale: { getValue: yesNoDash, sortable: true },
  category: {
    sortable: true,
    sortByName: 'artId__categoryId',
    fieldPath: ['art', 'category'],
    getValue: dict(),
    component: CategoryAutoComplete,
    fieldProps: { validate: [rules.required] },
  },
  catalogRaisonne: { component: TextArea, className: 'td200' }, 
  literature: { hiddenForTable: true, formFieldRenderer: LiteratureFn, getValue: getArrayValue },
  exhibition: { hiddenForTable: true, formFieldRenderer: ExhibitionsFn, getValue: getArrayValue },
  hammerPrice: {
    className: 'price',
    sortable: true,
    getValue: ({ value }) => value ? `$${ toNumber({ value }) }` : '−',
  },
  ...commonFields,
  ...getCarLotFieldsConfig({
    sortableFields: ['auctionDate', 'auctionId', 'auctionPriceUsd'],
    inlineEditFields: ['auctionDate', 'auctionId'],
  }),

};

export const columnsWithAcceptance = defineColumns(
  connectAcceptance(config, acceptanceFields),
);

export const defaultColumns = [
  'imageFileUrl',
  'title',
  'artist',
  'auctionId',
  'auctionDate',
  'saleNumber',
  'lotNumber',
  'estimatePrice',
  'soldPrice',
  'isSoldPriceUpdated',
  'soldPriceUsd',
  // 'hammerPrice'
];

const columns = defineColumns(config);

export const tableParams = { columns, defaultColumns };

export default columns;
