import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect, selector } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import columns from 'fields/lot';
import ValuesList from 'pages/ArtworkPage/ValuesList';
import { getValueListConfig } from 'lib/helpers';
import sheet from './sheet';
import injectSheet from 'lib/sheet';
import { dictionaryById } from 'domain/dictionary';
import { lot, onEdit } from 'domain/lotList';
import { acceptanceFields, lockGuarantee } from 'domain/lot';
import Menu from './Menu';
import { isDirty, isValid, getFormValues } from 'redux-form';
import { FormattedMessage as FM } from 'react-intl';

const lastSaleInfo = (isPrivateSale) => [
  ...columns.select(
    'artId',
    'auctionId',
    // 'auctionDate',
    'auctionSaleDate',
    'auctionSaleTime',
    'auctionTitle',
    'auctionLocation',
    'auctionSaleUrl',
  ),
  ...(isPrivateSale ? [] : columns.select('saleNumber', 'lotNumber')),
  ...columns.select(
    'lotUrls',
    'currency',
    'estimatePrice',
    'soldPrice',
  ),
]

const image = columns.select('defaultArtImage');

const sourceInfo = columns.select(
  'createdDate',
  'updatedDate',
  'acceptedDate',
  'sourceType',
);

const provenanceInfo = columns.select(
  'provenance',
);

const conditionInfo = columns.select(
  'conditionRaw',
  'condition',
);

const catalogRaisonneInfo = columns.select('catalogRaisonne');

export const literatureExhibitionInfo = columns.select('literature', 'exhibition');

const lotPriceInfo = columns.select(
  'estimatePrice',
  'soldPrice',
);
const lotPriceInfoUSD = columns.select(
  'estimateDiapasonUsd',
  'soldPriceUsd',
);

class AwLots extends React.Component {
  static propTypes = {
    list: PropTypes.instanceOf(I.Collection),
    currencies: PropTypes.instanceOf(I.Collection),
    classes: PropTypes.shape({
      block: PropTypes.string.isRequired,
      blockCustom:PropTypes.string,
      lotTabs: PropTypes.string.isRequired,
      tabItem: PropTypes.string.isRequired,
      tabButton: PropTypes.string.isRequired,
      tabId: PropTypes.string.isRequired,
    }).isRequired,
    select: PropTypes.func.isRequired,
    lot: PropTypes.instanceOf(I.Record),
    hasChanges: PropTypes.bool.isRequired,
    locked: PropTypes.bool,
    formData: PropTypes.object,
  };

  state = {
    showAll: false,
  }

  render() {
    const { list, classes, select, lot, hasChanges, formData, ...props } = this.props;
    const isEdit = lot.wfAcceptance !== 'ACCEPTED' || !lot.locked;
    const { showAll } = this.state;
    return <div className={classes.AwLots}>
      <ul className={classes.lotTabs}>
        {
          list
            .slice(0, showAll ? list.size : 8)
            .map((v, vKey) => (
              <li key={`lotTab-${v.id}-${vKey}`} className={classes.tabItem}>
                <button
                  onClick={v.id === lot.id ? () => null : () => select(v.id)}
                  className={cx(
                  classes.tabButton,
                  {
                    [classes[lot.wfAcceptance.toLowerCase()]]: v.id === lot.id,
                    [classes.warning]: isEdit && v.id === lot.id && hasChanges,
                  },
                )}
                  type="button"
              >
                  <span children={`${v.wfAcceptance} - ID `}/>
                  <span children={v.id} className={classes.tabId} />
                  { v.privateSale && <span className={classes.privateSale}>PRIVATE</span> }
                </button>
                { v.id === lot.id && <Menu {...{ ...props, isEdit, acceptanceFields, formData: formData || lot }} /> }
              </li>
          ))
        }
        { list.size > 8 && <FM id={showAll ? 'showLess' : 'showMore'} values={{ count: list.size - 8 }}>
          { (text) => <button
            className={classes.more}
            type="button"
            children={text}
            onClick={() => this.setState({ showAll: !showAll })}
          /> }
        </FM> }
      </ul>
      <div>
        <div className={classes.block}>
          <ValuesList showStars {...props} config={getValueListConfig(lastSaleInfo(lot.privateSale), isEdit)} rowData={lot} />
          <ValuesList showStars {...props} config={getValueListConfig(image, isEdit)} rowData={lot} />
          <ValuesList
            {...props}
            config={[
              ...sourceInfo,
              ...getValueListConfig(columns.select('guarantee'), isEdit)
            ]}
            rowData={lot}
          />
        </div>
        <div className={classes.block}>
          <ValuesList {...props} config={getValueListConfig(provenanceInfo, isEdit)} rowData={lot} />
          <ValuesList {...props} config={getValueListConfig(conditionInfo, isEdit)} rowData={lot} />
          <ValuesList {...props} config={getValueListConfig(literatureExhibitionInfo, isEdit)} rowData={lot} />
          <ValuesList {...props} config={getValueListConfig(catalogRaisonneInfo, isEdit)} rowData={lot} />
        </div>
        <div>
         <div className={classes.blockCustom}>
          <ValuesList {...props} config={lotPriceInfo} rowData={lot} />
          <ValuesList {...props} config={lotPriceInfoUSD} rowData={lot} />
          <ValuesList {...props} config={columns.select('alertAbv')} rowData={lot} />
          </div>
          <div>
          <ValuesList {...props} config={columns.select('hammerPrice')} rowData={lot} />
          </div>
        </div>
        <div className={classes.block}>
          <ValuesList {...props} config={columns.select('lowEstimateUsdPlusBp')} rowData={lot} />
          <ValuesList {...props} config={columns.select('medianEstimateUsdPlusBp')} rowData={lot} />
          <ValuesList {...props} config={columns.select('highEstimateUsdPlusBp')} rowData={lot} />
        </div>
      </div>
    </div>;
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    lot,
    select: onEdit,
    lockGuarantee,
    currency: dictionaryById('currencies'),
    condition: dictionaryById('conditions'),
    auctionId: dictionaryById('auctions'),
    hasChanges: selector((s) => isDirty('lotForm')(s), v => v),
    formData: selector(getFormValues('lotForm'), v => v),
    isFormValid: selector((s) => isValid('lotForm')(s), v => v),
  }),
)(AwLots);
