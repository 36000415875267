import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import axios from 'axios';
import { connect, selector } from 'cpcs-reconnect';
import {toCamel, toSnake} from 'lib/helpers';
import { getFormValues } from 'redux-form';

// Rules

import { token } from 'domain/env';

import sheet from './sheet';

import injectSheet from 'lib/sheet';
import { checkQuery } from 'lib/helpers';
import { getQuery } from 'domain/router/RouterModel';

const url = '/api/v1/artexpert/auto_selection/';

export class Input extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
      name: PropTypes.string,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    placeholder: PropTypes.string,
    token: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    filters: PropTypes.object.isRequired,
    query: PropTypes.object,
    pageQuery: PropTypes.object,
    fieldName: PropTypes.string,
  }

  timeOut = 0;

  state = {
    list: [],
    blocked: true,
  }

  static defaultProps = {
    type: 'text',
    autoComplete: 'disabled',
    hasWarning: false,
  }

  sendRequest = (value) => {
    const { token, input: { name }, model, filters, query, fieldName, pageQuery } = this.props;
    const { wfAcceptance, isDelete } = pageQuery;
    let filterModel = model;
    if (model === 'artwork'){
      filterModel = 'art';
    }
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      const { filterBy } = checkQuery({
        ...query,
        wfAcceptance,
        [name]: value,
      }, { ...filters[toCamel(filterModel)] });
      axios({
        url,
        method: 'get',
        headers: { 'Authorization': `Bearer ${token}` },
        params: {
          q: filterBy.replace(`${name}:`, `${fieldName || name}:`),
          field_name: fieldName || toSnake(name),
          model_name: toSnake(filterModel),
          is_delete: !!isDelete,
        },
      }).then(({ data: list }) => this.updateList(list));
    }, 300);
  }

  updateList = (list) => {
    if (!this.state.blocked) this.setState({ list });
  }

  onInputChange = (text = '') => {
    const { blocked } = this.state;
    const { input: { onChange } } = this.props;
    if (!blocked) {
      this.sendRequest(`*${text}*`);
      onChange(text ? `*${text}*` : '');
    }
  }

  setText = (text) => {
    this.setState({ list: [], blocked: true }, this.props.input.onChange(`"${text}"`));
  }

  render() {
    const { classes, intl: { formatMessage }, input, placeholder } = this.props;
    const { list } = this.state;
    return (
      <div className={classes.wrapper}>
        <input
          autoComplete="disabled"
          type="text"
          value={input.value}
          placeholder={formatMessage({ id: placeholder || `placeholder.${input.name}` })}
          className={classes.SearchInput}
          onChange={({ target: { value } }) => this.onInputChange(value)}
          onBlur={() => this.setState({ list: [], blocked: true })}
          onFocus={() => this.setState({ blocked: false })}
        />
        { !!list.length && <div className={classes.listBox}>
          { list
            .map(([ text, count ]) => <button
              key={`${text}-${count}`}
              tabIndex="-1"
              type="button"
              className={classes.item}
              onMouseDown={() => this.setText(text)}
            >
              <div className={classes.text}>{text}</div>
              <div className={classes.number}>({count})</div>
            </button> ) }
        </div> }
      </div>
    );
  }
}

export const PureInput = injectIntl(Input);
export default compose(
  injectSheet(sheet),
  connect({
    token,
    query: selector(getFormValues('filter'), v => v),
    pageQuery: getQuery,
  }),
)(PureInput);
