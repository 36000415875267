import { Tabs } from 'theme';
import colors, { statusColors } from 'theme/Colors';

export default {
  AwLots: {
    // root
  },
  block: {
    borderBottom: '1px solid #2e343e',
    padding: [30, 0, 40],
    display: 'flex',
  },

  blockCustom:{
    padding: [30, 0, 10],
    display: 'flex',
  },
  lotTabs: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  tabItem: {
    flex: '0 0 30px',
    position: 'relative',
  },
  tabButton: Tabs.btn,
  more: {
    extend: 'tabButton',
    color: '#000000',
    textDecoration: 'underline',
  },
  tabButtonActive: {
    color: colors.labelLink,
  },
  ...Object
    .keys(statusColors)
    .reduce((p, key) => ({ ...p, [key]: { ...statusColors[key], color: colors.text } }), {}),
  privateSale: {
    color: colors.text,
    backgroundColor: colors.orange,
    borderRadius: 14,
    padding: [5, 10],
    marginLeft: 10,
    fontSize: '12px',
  },
  tabId: {
    fontWeight: 'bold',
  },
  warning: {
    '&:before': {
      content: '""',
      width: 16,
      height: 14,
      display: 'block',
      position: 'absolute',
      left: 6,
      background: {
        size: '14px 16px',
        repeat: 'no-repeat',
        position: 'center center',
        image: 'url(' + require('./warning.svg') + ')',
      },
    },
  },
};
