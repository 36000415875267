import {connect} from "cpcs-reconnect";
import {dictionaryList} from "domain/dictionary";
import PropTypes from "prop-types";
import ReactSelect, {components} from 'react-select';

import React from 'react';
import I from 'immutable';
import {filterOption, formatGroupLabel} from "components/form/element/Select";
import {DEFAULT_FONTFAMILY} from "theme/const";
import colors from "theme/Colors";
import {Colors, zIndex} from "theme";

const styles = ({ meta: { touched, error }, hasWarning = false, isClearable = true, grouped = false }) => ({
    container: style => ({
      ...style,
      borderRadius: 0,
      fontFamily: DEFAULT_FONTFAMILY,
      boxSizing: 'border-box',
      border: touched && error ? 'solid 1px red' : 'solid 1px #d1d3d5',
      lineHeight: 'normal',
    }),
    placeholder: style => ({ ...style, color: Colors.placeholder }),
    dropdownIndicator: (style, { selectProps: { menuIsOpen } }) => {
      return {
        ...style,
        cursor: 'pointer',
        transform: menuIsOpen ? 'scale(0.8) rotate(-180deg)' : 'scale(0.8)',
        color: Colors.labelLink,
        marginRight: 4,
        padding: [],
        '&:hover': {
          color: Colors.labelLink,
        },
      };
    },
    clearIndicator: style => ({ ...style, cursor: 'pointer', transform: 'scale(0.8)', padding: [] }),
    multiValue: style => ({
      ...style,
      backgroundColor: Colors.buttonHover,
      paddingRight: 14,
      borderRadius: 4,
      margin: '0px 2px',
      position: 'relative',
    }),
    multiValueLabel: style => ({ ...style, color: Colors.text, fontSize: 'inherit' }),
    indicatorSeparator: style => (isClearable ? style : { display: 'none' }),
    indicatorsContainer: style => ({ ...style, position: 'absolute', right: 0, top: 0, height: '100%' }),
    multiValueRemove: () => ({
      backgroundColor: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      opacity: 0.8,
      justifyContent: 'flex-end',
      position: 'absolute',
      paddingRight: 2,
      paddingTop: 2,
      boxSizing: 'border-box',
    }),
    option: (style, state) => ({
      ...style,
      cursor: 'pointer',
      fontSize: '14px',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      padding: grouped ? '8px 12px 8px 24px' : '8px 12px',
      backgroundColor: state.isSelected || state.isFocused ? Colors.warning : 'transparent',
      color: Colors.text,
      lineHeight: '14px',
      '&:hover': {
        backgroundColor: Colors.warning,
      },
    }),
    menu: style => ({
      ...style,
      zIndex: zIndex.FORM_FIELD_SELECT,
      marginTop: 4,
      marginBottom: 0,
      borderRadius: 0,
      left: -1,
      width: 'calc(100% + 2px)',
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)',
      boxSizing: 'border-box',
    }),
    menuList: (style, state) => ({
      ...style,
      paddingBottom: 10,
      paddingTop: 10,
    }),
    control: (style, { isDisabled, isFocused }) => ({
      ...style,
      borderRadius: 0,
      opacity: isDisabled ? 0.5 : 1,
      minHeight: 26,
      height: 'auto',
      border: 'none',
      boxShadow: isFocused ? '0 0 3px 0 #4990e2' : 'none',
      backgroundColor: hasWarning ? Colors.warning : Colors.bg,
  }),
    valueContainer: style => ({ ...style, minHeight: 26, width: '100%', paddingRight: 45,padding:'0px 8px' }),
    singleValue: style => ({ ...style, width: '100%', paddingRight: 45 }),
    input: style => ({ ...style, boxSizing: 'border-box', overflow: 'hidden' }),
  });


const inputStyle=isHidden => ({
    background: 0,
    border: 0,
    fontSize: 'inherit',
    opacity: isHidden? 0:1,
    outline: 0,
    padding: 0,
    color: 'inherit',
    height: '16px',
});
const checkForGroupedList=list => I.List.isList(list)? list:list.reduce((p, n) => p.concat(n.options), []);

const Input=(props) => {
    return (
        <components.Input
            {...props}
            id={`${props.id}-search`}
            inputStyle={{
                ...inputStyle(props.isHidden),
                fontFamily: DEFAULT_FONTFAMILY,
                color: colors.menu,
                fontSize: '14px'
            }}
        />
    );
};

Input.propTypes={
    isHidden: PropTypes.bool,
    id: PropTypes.string,
};
class CustomSelect extends React.PureComponent {
    static propTypes={
        id: PropTypes.string,
        parseValue: PropTypes.func,
        parseLabel: PropTypes.func,
        filterOptions: PropTypes.func,
        noOptionsMessage: PropTypes.func,
        placeholder: PropTypes.string,
        isMulti: PropTypes.bool,
        grouped: PropTypes.bool,
        isClearable: PropTypes.bool,
        styles: PropTypes.func.isRequired,
        list: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(I.List)]).isRequired,
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
            name: PropTypes.string,
        }).isRequired,
        meta: PropTypes.shape({}).isRequired,
        hasWarning: PropTypes.bool,
        closeMenuOnSelect: PropTypes.bool,
    }

    static defaultProps={
        saveValues: false,
        parseValue: v => v&&v.get? parseInt(v.get('id'), 10):'',
        parseLabel: v => v&&v.get? v.get('title'):'',
        isMulti: false,
        isClearable: false,
        grouped: false,
        styles,
        list: new I.List(),
        filterOption,
        noOptionsMessage: () => null,
        hasWarning: false,

    }

    getValue() {
        const {input: {value}, parseValue, list, isMulti, grouped}=this.props;
        const pV=grouped? ({value}) => (value):parseValue;
        if (!value) return isMulti? []:'';
        if (typeof value!=='string'&&!!value.reduce) {
            return value.map(key => checkForGroupedList(list).find(v => pV(v).toString()===key.toString()));
        }
        return checkForGroupedList(list).find(v => pV(v).toString()===value.toString());
    }

    toFormValue=value => {
        const {parseValue, isMulti, grouped}=this.props;
        const parser=grouped? v => v.value:parseValue;
        if (value) {
            return isMulti? value.map(parser):parser(value);
        }
        return isMulti? []:null;
    }

    render() {
        const {
            input: {name, onFocus, onChange}, grouped, id, placeholder,
            MenuList, MultiValueContainer, Placeholder, ValueContainer,
            closeMenuOnSelect,
            meta, list, parseValue, parseLabel, styles, ...props
        }=this.props;
        
        const dependsOngroupedProps=grouped?
            {formatGroupLabel}:
            {
                getOptionValue: parseValue,
                getOptionLabel: parseLabel,
            };
        return <div data-name={`autocomplete--${name}`} style={{width: '100%'}}>
            <ReactSelect

                name={name}
                inputId={id}
                id={`${id||''}-container`}
                onChange={(v) => onChange(this.toFormValue(v))}
                value={this.getValue()}
                options={list}
                styles={styles({...this.props, grouped})}
                onFocus={() => onFocus&&onFocus()}
                closeMenuOnSelect={closeMenuOnSelect}
                components={{
                    Input,
                }}
                placeholder={placeholder}
                {...dependsOngroupedProps}
                {...props}
            />
        </div>;
    }
}



export const CustomCurrencyAutoComplete=connect({list: dictionaryList('currencies')})(CustomSelect);
