
export const decimalValueNormalize = (value, previousValue) => {
    // Allow empty input to clear the field
    if (value === undefined || value === null || value === '') return '';

    // Regular expression to check if the input contains any alphabetic characters
    const containsAlphabet = /[a-zA-Z]/;
    if (containsAlphabet.test(value)) return previousValue || '';

    // If the input is not a valid number (excluding empty strings), return previousValue
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return previousValue || '';

    // Ensure the string representation of the number has at most 4 decimal places
    const decimalPlacesMatch = value.match(/^(\d+)(\.\d{0,4})?$/);
    if (!decimalPlacesMatch) return previousValue || '';

    // Clamp the number between 0 and 1
    if (numericValue < 0) return '0';
    if (numericValue > 1) return '1';

    return value; // Valid value is returned
};

export const normalizeInteger=(value, previousValue) => {
    if (!value) return '';

    const onlyDigits=value.replace(/[^\d]/g, '');

    if (onlyDigits.startsWith('0')) return previousValue||'';

    return Number(onlyDigits);
};

export const formatWithCommas=(value) => {
    if (!value) return '';

    const onlyDigits=value.toString().replace(/[^\d]/g, '');
    return new Intl.NumberFormat('en-US').format(onlyDigits);
};


