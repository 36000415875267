import React from 'react';
import PropTypes from 'prop-types';
import { compose} from 'redux';
import { connect} from 'cpcs-reconnect';
import { Field, FieldArray,submit, reduxForm} from 'redux-form';
import { submit as localSubmit } from 'lib/helpers';
import { push} from 'connected-react-router';
import injectSheet from 'lib/sheet';
import { lnk} from 'lib/routes';
import Paper from 'components/Paper';
import Block from 'components/Block';
import Header from 'components/Header';
import Accordion from 'components/Accordion';
import ValuesList from 'components/ValuesList';
import Actions from 'components/Actions';
import HistoryPopup from 'popups/History';
import confirmation from 'popups/Confirmation';
import { remove} from 'domain/const';
import Navigation from 'components/Navigation';
import { userProfile} from 'domain/env';
import { selectors, actions} from 'domain/auction';
import { dictionaryById } from 'domain/dictionary';
import { columns } from 'fields/auction';
import { BPRatesTable } from './components/BPRatesTable';

const { item } = selectors;
const { updateItemAction } = actions;
export const editableFields = ['name', 'country', 'city'];

const sheet={
  ItemPage: {
    display: 'flex',
    flexDirection: 'column',
    padding: [20, 20],
    height: 'calc(100vh - 110px)',
    boxSizing: 'border-box',
  },
  container: {},
  box: {
    padding: [60, 40],
    display: 'flex',

  },
};


const getHeaderTitle = (item) => item.get('name')||item.get('title');
const RemovePopup = confirmation('remove', remove, 'popupAction.remove.single');

const connections={
  item,
  country: dictionaryById('countries'),
};

 const createFormConfig = (fields) => fields
  .map(({ formFieldRenderer, component, fieldProps, name, ...config }) => ({
    name,
    ...config,
    isEdit: true,
    parser: (params) =>
      typeof formFieldRenderer === 'function' ? formFieldRenderer({
        ...config,
        ...params,
      }) :
      <Field
        name={name}
        component={component}
        {...fieldProps}
      />,
  }));

const config={
  basicInfo: [
    createFormConfig(columns.select(...editableFields,)),
    [],
    columns.select('id', 'createdDate', 'updatedDate', 'lotsCount'),
  ],
};


 const createSimpleForm = ({ form, action }) => {
  class Form extends React.PureComponent {
    static propTypes = {
      children: PropTypes.any,
     
    }
    render(){
      const { children } = this.props;
      return (
        <form >
          { children }
        </form>
      );
    }
  }
  return reduxForm({ form,
     onSubmit: localSubmit(action)})(Form);
};

const Form =createSimpleForm({
  form: 'auctionForm',
  action: updateItemAction,
});

const filterInternalEnt=(item, config) => {
  const cloneConfig={...config};
  if (item.id===31) {
    delete cloneConfig['clientPersonalizationSpace'];
  }
  return cloneConfig;
};

const AuctionPage=({classes, item, submit, push,...props}) => {
  const titleFn=getHeaderTitle;
  const readOnly=false;
  const name='auction';
  const _config=filterInternalEnt(item, config);

  const renderBPTable=() => (
    <FieldArray name="bpRates" component={BPRatesTable} />
  );

  const renderItems=() => {
    return [Object.keys(_config).map((key) => (
      <Block key={key}>
        <Accordion title={key}>
          <div className={classes.box}>
            {typeof _config[key]==='function'
              ? _config[key](item)
              :_config[key].map((fields, index) => (
                <ValuesList
                  key={`${key}-${index}`}
                  config={fields}
                  {...props}
                  rowData={item}
                />
              ))}
          </div>
        </Accordion>
      </Block>
    )),
    <Block key={'BP Rates'}>
      <Accordion  title={'BP Rates'}>
        <div style={{minHeight:'400px'}} className={classes.box}>
          {renderBPTable()}
        </div>
      </Accordion>
    </Block>];
  };

  if (!item.id) return null;


  return (
    <div className={classes.ItemPage}>
      <Navigation linkBuilder={(id) => lnk(name, {id})} />
      <HistoryPopup />
      <RemovePopup />
      <Header
        title={titleFn(item)}
        object={item}
        onSave={readOnly||!Form? null:() => submit(`${name}Form`)
         }
        onCancel={() => push(lnk(`${name}List`))}
      />
      {!item.isDelete&&<Actions data={item} {...props} />}
      <Paper className={classes.container}>
        {readOnly||!Form||item.locked? (
          renderItems()
        ):(
          <Form  classes={classes}>{renderItems()}</Form>
        )}
      </Paper>
    </div>
  );
};


AuctionPage.propTypes={
  classes: PropTypes.shape({
    ItemPage: PropTypes.string,
    container: PropTypes.string,
    logo: PropTypes.string,
    link: PropTypes.string,
    button: PropTypes.string,
    box:PropTypes.string,
  }).isRequired,
  submit: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  item: PropTypes.shape({}).isRequired,
};

export default compose(
  injectSheet(sheet),
  connect({
    ...connections,
    submit,
    userProfile,
    push,
  }),
)(AuctionPage);


