import { fork, select, call, put } from 'redux-saga/effects';
import watchNavigate from './navigator.js';
import watchRouter from './router';
import checkModalMode from './checkModalMode';
import Api, { callApi, watchCallApiAction } from 'domain/api';
import { checkAuth, isAuthorized, token, addNotification, userProfileAction, refreshToken as refTok, logIn } from 'domain/env';
import { ensureDictionaries } from 'domain/dictionary/sagas';
import { getAcl } from 'lib/helpers';

const resolveOrError = (f, ...args) => {
  return f(...args).catch(e => { throw e; });
};

function* checkStoredToken() {
  const storedToken = yield select(token);
  const refToken = yield select(refTok);
  const hasAuth = yield select(isAuthorized);
  if (storedToken && refToken && !hasAuth){
      try {
        const { data: { access } } = yield call(resolveOrError, Api.refreshToken, { data: { refresh: refToken } });
        const token = access;
        yield put({ type: logIn.success, payload: { token ,refreshToken: refToken} });
        const { data } = yield callApi(Api.getUserData, { token });
        yield put({ type: checkAuth.success, payload: { token, refreshToken: refToken } });
        yield put({ type: userProfileAction.success, payload: { ...data, acl: getAcl(data) } });
      } catch (e) {
        yield put({ type: checkAuth.failure });
        //yield put(routerPush(lnk('login')));
        yield put(addNotification({ type: 'error', title: 'Token has expired, please auth again' }));
      }
  } else {
    yield put({ type: checkAuth.failure });
  }
}

export default function* root() {
  yield fork(checkStoredToken);
  yield fork(watchNavigate);
  yield fork(watchRouter);
  yield fork(ensureDictionaries);
  yield fork(checkModalMode);
  yield fork(watchCallApiAction);
}
