import colors from "theme/Colors";
import {DEFAULT_FONTFAMILY} from "theme/const";
import {createUseStyles} from "react-jss";

export const useBPRatesTableStyles=createUseStyles({
    outermostContainer: {
      position: 'relative',
      color: colors.text,
      fontSize: 14,
      fontFamily: DEFAULT_FONTFAMILY,
      fontWeight: 400,
    },
  
    dataFieldWidth: {
      minWidth: '100px',
      height: '26px !important',
    },
    field: {
      height: '26px',
      width: '106px',
      border: `1px solid ${colors.fieldBorder}`,
      textAlign: 'center'
  
    },
    selectField: {
      // height: '32px',
    },
    tableHeading: {
      width: '106px',
      padding: '6px',
    },
    tableClass: {
      'borderCollapse': 'collapse',
    },
    tableRowClass: {
      // borderBottom: "2px solid #AA4423",
    },
  
    addBtnContainer: {
      width: 'fit-content',
      transform: 'translate(-40px,-27px)',
  
    },
    addBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      backgroundColor: '#fff',
      border: '2px solid #ccc',
      borderRadius: '50%',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      // transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: '#dc3545',
        borderColor: '#dc3545',
  
        '& $icon': {
          color: '#fff',
        },
      },
    },
    icon: {
  
      fontSize: '12px',
      color: '#333',
      transition: 'all 0.3s ease',
  
    },
    tableBodyRow: {
      backgroundColor: 'white !important',
  
    },
    removeBtnContainer: {
      minWidth: '0px !important',
      width: '0px !important',
      position: 'relative',
      padding:'0px !important',
  
    },
    removeButton: {
      backgroundColor: 'white',
      width: "24px",
      height: "24px",
      borderRadius: "50%", 
      border: "1px solid #e74c3c", 
      color: "#e74c3c", 
      fontSize: "12px",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      margin: "6px 0", 
      "&:hover": {
        backgroundColor: "#e74c3c",
        color: "#fff", 
      },
    },
    error: {
      borderColor: 'red !important',
    }
  }
  );
  