import {
  boolFilter,
  commonFilters,
  createFilterTransfer,
  dateParser,
  dateRange,
  intRange,
  NewPrice,
  stringFilter,
  intFilter,
  CategoryAutoComplete,
} from './common';

export const defaultList = [ 'id', 'artId', 'auctionId', 'auctionDate' ];

// apply columns to filter/table (from columns selector popup)
const columnsTransfer = {
  itemUrls: [ 'lotUrls' ],
  artworkTitle: [ 'title' ],
};

const filters = {
  auctionId: stringFilter('auctionLot'),
  artist: stringFilter('auctionLot', 'art_id__artist_id'),
  auctionDate: { parser: dateParser, formFieldRenderer: dateRange('auctionDate', 'placeholder.date') },
  saleNumber: stringFilter('auctionLot'),
  lotNumber: stringFilter('auctionLot'),
  itemUrls: stringFilter('auctionLot'),
  currency: stringFilter('auctionLot'),
  // category: stringFilter('auctionLot', 'category_id'),
  category: {
    component: CategoryAutoComplete,
    key: 'category_id',
    fieldProps: { parseValue: v => v && v.get ? v.get('id') : '' },
  },
  isSoldPriceUpdated: { formFieldRenderer: NewPrice },
  soldPriceUsd: { formFieldRenderer: intRange('soldPriceUsd') },
  estimatePriceStartUsd: { formFieldRenderer: intRange('estimatePriceStartUsd') },
  estimatePriceEndUsd: { formFieldRenderer: intRange('estimatePriceEndUsd') },
  artworkTitle: stringFilter('auctionLot'),
  artIdId: intFilter('auctionLot'),
  artistIdWfAcceptance: stringFilter('auctionLot', 'art_id__artist_id__wf_acceptance'),
  artIdWfAcceptance: stringFilter('auctionLot', 'art_id__wf_acceptance'),
  usernameLastUpdate: stringFilter('auctionLot'),
  soldPriceUnitRaw: stringFilter('auctionLot'),
  soldPriceRaw: stringFilter('auctionLot'),
  alertAbv: { formFieldRenderer: intRange('alertAbv') },
  guarantee: { formFieldRenderer: boolFilter('guarantee') },
  artistShowRtv: { formFieldRenderer: boolFilter('artistShowRtv'), key: 'art_id__artist_id__show_rtv' },
  hasSimilarImages: { formFieldRenderer: boolFilter('hasSimilarImages'), key: 'art_id__has_similar_images' },
  hasRepeatSales: { formFieldRenderer: boolFilter('hasRepeatSales'), key: 'art_id__has_repeat_sales' },
  privateSale: { formFieldRenderer: boolFilter('privateSale') },
  highEstimateUsdPlusBp: { formFieldRenderer: intRange('highEstimateUsdPlusBp') },
  medianEstimateUsdPlusBp: { formFieldRenderer: intRange('medianEstimateUsdPlusBp') },
  lowEstimateUsdPlusBp: { formFieldRenderer: intRange('lowEstimateUsdPlusBp') },
  hammerPrice: { formFieldRenderer: intRange('hammerPrice') },
  ...commonFilters,
};


export const apiFilters = {
  ...filters,
  artistSalePairs: {
    ...stringFilter('auctionLot', 'art_id__artist_id_id'),
    tempParser: (key, value) => `(art_id__artist_id_id:${value})AND(sold_price_usd:[1 TO *])AND(repeat_lot_count:[2 TO *])`,
  },
};

export const filterTransfer = createFilterTransfer(filters, columnsTransfer);
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer };

export default filters;
