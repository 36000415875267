const placeHoldersData = {
  username: 'Username',
  email: 'Email',
  sourceType: 'Select source type',
  fullName: 'First and last name',
  residences: 'Type or select countries',
  birthDate: 'YYYY',
  deathDate: 'YYYY',
  biography: 'Type here',
  year: 'YYYY',
  date: 'MM/DD/YYYY',
  subjects: 'Portrait, landscape...',
  signature: 'Type signature',
  medium: 'Type medium',
  mediumRaw: 'Raw medium value',
  medium3d: 'Select medium',
  physicalSizeWidth: '0.0',
  physicalSizeHeight: '0.0',
  physicalSizeDepth: '0.0',
  currency: 'Select Currency',
  physicalSizeRaw: 'Type dimensions',
  sheetSizeRaw: 'Type dimensions',
  imageSizeRaw: 'Type dimensions',
  category: 'Select category',
  title: 'Type title',
  artistId: 'Select artist',
  yearCreatedFrom: 'YYYY',
  yearCreatedTo: 'YYYY',
  literature: 'Where art was mentioned',
  exhibitions: 'Specify exhibition history',
  exhibition: 'Specify exhibition history',
  catalogRaisonne: 'Indicate edition of the catalog',
  notes: 'Internal notes',
  imageFileUrl: '−',
  auctionId: 'Type or select auction',
  auctionDate: 'MM/DD/YYYY',
  saleNumber: 'Enter Sale number',
  lotNumber: 'Enter Lot number',
  itemUrl: 'Enter url address',
  estimatePrice: '−',
  soldPrice: 'Enter sold price',
  condition: 'Type or select condition',
  provenance: 'Provenance not present',
  conditionRaw: 'Condition not present',
  isSignature: 'Signed',
  conceptionDateFrom: 'YYYY',
  conceptionDateTo: 'YYYY',
  inscription: 'Inscription text',
  studio: 'Studio',
  authenticityLetter: 'Letter of Authenticity',
  foundry: 'Foundry',
  stamps: 'Stamp',
  edition: 'Edition',
  plate: 'Plate number',
  printer: 'Printer name',
  publisher: 'Publisher name',
  numberedNo: 'xx',
  numberedOf: 'xx',
  editionSize: 'xx',
  artistProof: 'xx',
  pressProof: 'xx',
  artId: 'Type ID or select artwork',
  current: 'Status',
  carValue: 'CAR',
  configuration: 'Choose configuration',
  userStatus: 'Choose status',
  freeRtvPurchaseCount: '0',
  namePrefix: 'Prefix',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  nameSuffix: 'Suffix',
  physicalSizeUnits: 'Units',
  bannerDisplayDateFrom: 'from',
  bannerDisplayDateTo: 'to',
  bannerUrl: 'URL',
  trialRemaining: 'Trial Remaining',
  showRtv: 'RTV',
  privateSale: 'PrivateSale',
  seatType: 'Select category',
  hammerPrice:'Hammer Price'
};

// Auction
const auction = {
  id: 'ID',
  name: 'Title',
  country: 'Country',
  city: 'City',
  lotsCount: 'Lots',
  createdDate: 'Created',
  updatedDate: 'Updated',
  // bpRages:"Bp Rates"
  'BP Rates': 'BP Rates'
};

const trialPage = {
  settingsPage: 'Settings',
  trialPage: 'Trial Settings',
  freeAbvPurchasesCount: 'Free ABV Purchases',
};

// Subject
const subject = {
  id: 'ID',
  title: 'Title',
  level: 'Level',
};

// Subject
const coupon = {
  addCoupon: 'Add Coupon',
  couponList: 'Coupons',
  coupons: 'Coupons',
  id: 'ID',
  title: 'Title',
  expireOn: 'Expiration Date',
  maximumRedemption: 'Max Redemption',
  state: 'State',
  active: 'State',
};

const substrate = {
  id: 'ID',
  rank: 'Rank',
  order: 'Order',
  description: 'Description',
  values: 'Values',
  substratevalues: 'Values',
  'values.value': 'Value',
};

const medium = {
  id: 'ID',
  rank: 'Rank',
  order: 'Order',
  value: 'Value',
};

const sale = {
  id: 'ID',
  title: 'Title',
  auction: 'Auction',
  saleNumber: 'Sale #',
};

// Customer
const customer = {
  id: 'ID',
  active: 'Active',
  alertsCount: 'Alert Count',
  artsCount: 'My Collection',
  company: 'Company',
  considerationsCount: 'Watchlist',
  email: 'Email',
  emailVerified: 'Email Verified',
  picture: 'Profile Picture',
  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone',
  lastLogin: 'Last login',
  marketingEmailConsent: 'Marketing',
  sharedWithUser: 'Shared with User',
  sharedByUser: 'Shared by User',
  rtv: 'ABV',
  country: 'Country',
  occupations: 'User Role',
  stripePlan: 'Stripe Plan',
  subscription: 'Subscription',
  userStatus: 'User Status',
  configuration: 'Config',
  renewalDate: 'Renewal Date',
  abvPurchases: 'ABV purchases',
  stripeUserUrl: 'Stripe User URL',
  totalAbv: 'Total ABV',
  byMobile: 'App Type',
  backToUser: 'Back to user ',
  showCollect: 'Show My Collection',
  showTrack: 'Show Watchlist',
  showAlerts: 'Show Alerts',
  showPurchases: 'Show Purchases',
  configure: 'Change Configuration',
  activeStripe: 'Active Stripe',
  activeByPayment: 'Active By Payment',
  beta: 'Beta',
  trial: 'Trial',
  settings: 'Settings',
  inactive: 'Inactive',
  createdDate: 'Created',
  acceptedDate: 'Accepted',
  updatedDate: 'Updated',
  freeRtvPurchaseCount: 'Included ABV',
  artwork: 'Artwork',
  trialRemaining: 'Trial Remaining',
  enterprise: 'Enterprise',
  seatType: 'Seat Type',
};

const customerFilters = {
  ...customer,
  lastName: 'Last<br/>Name',
  artwork: 'Artwork',
};

const purchase = {
  purchaseList: ' Purchases',
  image: 'Image',
  title: 'Title',
  artist: 'Artist',
  rtvPrice: 'Value (USD)',
  purchasedDate: 'Date',
  current: 'Status',
  datePurchase: 'Date',
  pdf: 'PDF',
  push: 'Update ABV',
};

const popupNames = {
  mergeArtist: 'Merge with',
  history: 'History',
  remove: 'Delete Confirmation',
  ignore: 'Ignore Confirmation',
  draft: 'Draft Confirmation',
  validate: 'Validate Confirmation',
  restore: 'Restore Confirmation',
  addArtist: 'Add New Artist',
  addArtwork: 'Add New Artwork',
  addArtistArtwork: 'Add New Artwork',
  enterpriseWebappClientLogo: 'Client Logo',
  addLot: 'Add New Lot',
  merge: 'Merge Confirmation',
  crop: 'Update Image',
  addAuction: 'Add auction',
  addSubject: 'Add subject',
  addSurface: 'Add surface',
  addSubstrate: 'Add substrate',
  addMedium: 'Add 3D Medium',
  addSmctag: 'Add Tag',
  addSale: 'Add Sale',
  addCoupon: 'Add New Coupon',
  addEnterprise: 'Add Enterprise',
  approveArtist: 'Confirm Unaccept',
  approveArtwork: 'Confirm Action',
  approveLot: 'Confirm Action',
  pushAbv: 'Confirm Action',
  confirmUpdate: 'Confirm Action',
  accept: 'Accept Confirmation',
  display: 'Display Confirmation',
  update: 'Update',
  bulkChangePopup: 'Bulk Update',
  runAbv: 'Confirm Action',
  updateAbv: 'Confirm Action',
  syncAbv: 'Confirm Action',
};

const plural = '{subject, plural, one {item} other {items} }';

const popupActions = {
  remove: `Are you sure you want to delete <strong>{subject}</strong> ${plural}?<br/>This action can not be undone.`,
  'remove.single':
    'Are you sure you want to delete <strong>{subject}</strong>?<br/>This action can not be undone.',
  ignore: `Are you sure you want to mark <strong>{subject}</strong> ${plural} as Ignored?`,
  draft: `Are you sure you want to mark <strong>{subject}</strong> ${plural} as Draft?`,
  validate: `Are you sure you want to mark <strong>{subject}</strong> ${plural} as Valid?`,
  restore: `Are you sure you want to restore <strong>{subject}</strong> ${plural}?`,
  merge: 'Are you sure you want to merge <strong>{subject}</strong>?',
  approveArtist:
    'This Artist has accepted AOs, are you sure you want to unaccept it?',
  approveArtwork:
    'This AO has lots in {status} status, are you sure you want to continue?',
  approveLot:
    'This Lot is linked to AO in {status} status, are you sure you want to continue?',
  pushAbv: 'Are you sure you want to update ABV?',
  accept: `Are you sure you want to mark <strong>{subject}</strong> ${plural} as Accepted?`,
  display: `Are you sure you want to mark <strong>{subject}</strong> ${plural} as Display?`,
  update: `Are you sure you want to make changes to <strong>{subject}</strong> ${plural}?`,
  bulkChangePopup: 'Check fields and their new values, you want to update:',
  confirmUpdate: 'Are you sure you want to save changes?',
  runAbv: 'Are sure you want to run Manifold reclusterization?',
  updateAbv:
    'Are you sure you want Custom Clusterization to be applied for AE ABV calculation?',
  syncAbv: 'Are you sure you want to sync AE ABV with WebApp?',
};

const history = {
  username: 'Data Team',
  dateCreated: 'Date',
  action: 'Actions',
  noChangedFields: 'No fields updated',
};

const lotPrices = {
  createdDate: 'Scraped Date',
  soldPriceRaw: 'Sold Price Raw',
  actions: '*',
  itemUrl: 'Link',
};

const popups = Object.keys(popupNames).reduce(
  (p, n) => ({ ...p, [`popup.${n}`]: popupNames[n] }),
  {},
);

const addPrefix = (obj, ...prefixes) =>
  Object.keys(obj).reduce(
    (p, n) => ({ ...p, [`${[...prefixes, n].join('.')}`]: obj[n] }),
    {},
  );

const placeHolders = Object.keys(placeHoldersData).reduce(
  (p, n) => ({ ...p, [`placeholder.${n}`]: placeHoldersData[n] }),
  {},
);

const withContext = (context, type, list) =>
  Object.keys(list).reduce(
    (p, n) => ({ ...p, [`filter.${context}.${type}.${n}`]: list[n] }),
    {},
  );

const artistFilterPlaceholders = {
  id: 'Type artist ID',
  fullName: 'Type artist name',
  residences: 'Type or select countries',
  mergeWith: 'Type or select artist',
  carTag: 'Tag',
  namePrefix: 'Prefix',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  nameSuffix: 'Suffix',
  usernameLastUpdate: 'Data Team',
  showRtv: 'RTV',
  carValue: 'Middle',
};

const smcTags = {
  carTag: 'Tag',
  fullName: 'Full Name',
  lowCarValue: 'Low',
  carValue: 'Middle',
  highCarValue: 'High',
  mgvEnabled: 'MGV',
  csvFile: 'Upload',
  period: 'Period',
  'placeholder.defaultCarField': 'Default Car Value',
  defaultCarField: 'Default Car Value',
  decayCoefficient: 'Decay Coefficient',
  'filter.smctag.label.smcTag': 'Tag',
  'filter.smctag.label.fullName': 'Full Name',
  'filter.smctag.placeholder.smcTag': 'Tag',
  'filter.smctag.placeholder.fullName': 'Full Name',
};

const artistFilterLabels = {
  id: 'ID',
  fullName: 'Name',
  residences: 'Countries',
  birthDate: 'Birth',
  deathDate: 'Death',
  mergeWith: 'Merged with',
  sourceType: 'Source',
  createdDate: 'Created',
  acceptedDate: 'Accepted',
  updatedDate: 'Updated',
  carTag: 'Tag',
  carValueArtist: 'CAR',
  namePrefix: 'Prefix',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  nameSuffix: 'Suffix',
  usernameLastUpdate: 'Data Team',
  searchCount: 'Search Count',
  alertsCount: 'Alert Count',
  showRtv: 'RTV',
  carValue: 'Middle',
};

const artworkFilterLabels = {
  id: 'ID',
  title: 'Title',
  sourceType: 'Source',
  artistId: 'Artist',
  createdDate: 'Created',
  updatedDate: 'Updated',
  acceptedDate: 'Accepted',
  auctionHouseLast: 'Auction',
  category: 'Category',
  medium: 'Medium',
  mediumRaw: 'Medium Raw',
  medium3d: '3D Mediums',
  isSignature: 'Signed',
  physicalSizeWidth: 'Width',
  physicalSizeHeight: 'Height',
  physicalSizeDepth: 'Depth',
  physicalSizeRaw: 'Size Raw',
  area: 'Area',
  yearCreatedFrom: 'Year From',
  yearCreatedTo: 'Year To',
  estimatePriceLast: 'Estimate',
  estimatePriceStartLast: 'Estimate Low',
  estimatePriceEndLast: 'Estimate High',
  abvLastSoldPrice: 'Sold$ (CAR)',
  epLastUsd: 'Estimate, $',
  epStartLastUsd: 'Estimate Low, $',
  epEndLastUsd: 'Estimate High, $',
  spLast: 'Sold Price',
  spLastUsd: 'Sold<br/>Price, $',
  aeRtv: 'AE ABV',
  rtvLast: 'WEB ABV',
  rtvDate: 'WEB ABV Updated',
  outliers: 'Outliers',
  auctionDateLast: 'Auction Date',
  lastSaleNumber: 'Sale #',
  lastLotNumber: 'Lot #',
  primarySurfaceRank: 'Surface Rank',
  primarySubstrateRank: 'Substrate Rank',
  lastLotCurrency: 'Currency',
  artistIdId: 'Artist ID',
  artistIdWfAcceptance: 'Artist Status',
  artIdWfAcceptance: 'Art Status',
  usernameLastUpdate: 'Data Team',
  abvQualityBenchmark: 'ABV, %',
  alertAbv: 'Alert ABV',
  alertHighAbv: 'Alert High ABV',
  alertMiddleAbv: 'Alert Middle ABV',
  alertLowAbv: 'Alert Low ABV',
  highEstimate: 'High Est + BP',
  middleEstimate: 'Median Est + BP',
  lowEstimate: 'Low Est + BP',
  abvMetricsManifold: 'Image ABV',
  abvMetricsMediums: 'Medium ABV',
  abvMetricsArea: 'Area ABV',
  abvMetricsPpsi: 'PPSI Filter',
  lastGuarantee: 'Guaranty',
  countOfLots: 'Lots Count',
  hasSimilarImages: 'Similar AOs',
  hasRepeatSales: 'Repeat Sale',
  artistShowRtv: 'ABV Artist',
  carValue: 'AO CAR',
  lowCarAbv: 'Low ABV',
  middleCarAbv: 'Middle ABV',
  highCarAbv: 'High ABV',
  lowSoldPriceUsdWithCar: 'Low CAR $',
  middleSoldPriceUsdWithCar: 'Middle CAR $',
  highSoldPriceUsdWithCar: 'High CAR $',
  lastAlertAbv: 'Alert ABV',
  clusterNumber: 'Cluster #',
};

const artworkFilterPlaceholders = {
  ...artworkFilterLabels,
  artistId: 'Select Artist',
  category: 'Select Category',
  physicalSizeWidth: '0.0',
  physicalSizeHeight: '0.0',
  physicalSizeDepth: '0.0',
  outliers: 'please select outliers',
  auctionHouseLast: 'All Lots Auction houses',
  primarySurfaceRank: '0',
  primarySubstrateRank: '0',
  lastLotCurrency: 'Select Currency',
  artistIdId: 'Artist ID',
  usernameLastUpdate: 'Data Team',
  clusterNumber: 'Cluster #',
};

const AwPageLotsSection = {
  id: 'ID',
  artId: 'Artwork',
  imageFileUrl: 'Image',
  sourceType: 'Source',
  acceptedDate: 'Accepted',
  soldPrice: 'Sold Price',
  estimatePrice: 'Estimate',
  currency: 'Currency',
  provenance: 'Provenance',
  conditionRaw: 'Scraped condition',
  'placeholder.auctionId': 'Auction',
  'placeholder.auctionDate': 'Auction Date',
  'placeholder.saleNumber': 'Sale #',
  'placeholder.lotNumber': 'Lot #',
  'placeholder.itemUrl': 'Item URL',
  'placeholder.currency': 'Currency',
  'placeholder.estimatePrice': 'Estimate',
  'placeholder.soldPrice': 'Sold Price',
  'placeholder.abvMetricsManifold': 'Manifold',
  'placeholder.abvMetricsMediums': 'Medium ABV',
  'placeholder.abvMetricsArea': 'Area ABV',
  'placeholder.abvMetricsPpsi': 'PPSI Filter',
  'placeholder.guarantee': 'Guaranty',
  'placeholder.lastGuarantee': 'Guaranty',
  'placeholder.artistShowRtv': 'ABV Artist',
  'placeholder.hasSimilarImages': 'Similar AOs',
  'placeholder.hasRepeatSales': 'Repeat Sale',
  'placeholder.physicalSizeRaw': 'Raw physical size',
  showMore: 'Show {count} more lots',
  showLess: 'Show less',
};

const lotFilterLabels = {
  id: 'ID',
  sourceType: 'Source',
  condition: 'Condition',
  auctionId: 'Auction',
  auctionDate: 'Auction Date',
  saleNumber: 'Sale #',
  lotNumber: 'Lot #',
  soldPriceUsd: 'Sold<br/>Price, $',
  isSoldPriceUpdated: 'New Price',
  estimatePriceStartUsd: 'Estimate Low, $',
  estimatePriceEndUsd: 'Estimate High, $',
  estimatePriceStart: '0.00',
  estimatePriceEnd: '0.00',
  artworkTitle: 'Artwork',
  artIdId: 'AO ID',
  createdDate: 'Created',
  updatedDate: 'Updated',
  acceptedDate: 'Accepted',
  lotUrls: 'Item URL',
  auctionTitle: 'Auction Title',
  auctionLocation: 'Auction Location',
  auctionSaleUrl: 'Auction URL',
  auctionSaleDate: 'Auction Date',
  auctionStartDate: 'Start',
  // auctionEndDate: 'End',
  auctionSaleTime: 'Auction Time',
  auctionStartTime: 'Start',
  auctionEndTime: 'End',
  itemUrls: 'Item URL',
  currency: 'Currency',
  artist: 'Artist',
  artistIdWfAcceptance: 'Artist Status',
  artIdWfAcceptance: 'Art Status',
  usernameLastUpdate: 'Data Team',
  soldPriceUnitRaw: 'Currency (raw)',
  soldPriceRaw: 'Price (raw)',
  alertAbv: 'Alert ABV',
  guarantee: 'Guaranty',
  artistShowRtv: 'ABV Artist',
  hasSimilarImages: 'Similar AOs',
  hasRepeatSales: 'Repeat Sale',
  privateSale: 'Private Sale',
  highEstimateUsdPlusBp: 'HIGH EST$+BP',
  medianEstimateUsdPlusBp: 'MEDIAN EST$+BP',
  lowEstimateUsdPlusBp: 'LOW EST$+BP',
  category: 'Category',
};

const lotFilterPlaceholders = {
  ...lotFilterLabels,
  currency: 'Select Currency',
};

const Alert = {
  id: 'ID',
  title: 'Title',
  alertList: 'Alerts',
  artist: 'Artist',
  count: 'Alert Count',
};

const alertFilters = {
  ...Alert,
  alertLotsCount: 'Lots',
};

const artworkCARLots = (index) => {
  const prefix = index === null ? '' : `carLot${index}__`;
  // '' for null
  // for numbers from 0: 1st or 2nd or 3rd or 4th or 5th
  const titlePrefix =
    index === null
      ? ''
      : index + 1 + (['st', 'nd', 'rd'][index] || 'th ') + ' ';
  return {
    [`${prefix}auctionDate`]: titlePrefix + 'Auction Date',
    [`${prefix}auctionId`]: titlePrefix + 'Auction House',
    [`${prefix}auctionPriceUsd`]: titlePrefix + 'Auction price (USD)',
    [`${prefix}auctionInterval`]: titlePrefix + 'Auction Interval',
    [`${prefix}auctionCar`]: titlePrefix + 'Auction CAR',
    [`${prefix}highEstimateUsdPlusBp`]: titlePrefix + 'HIGH EST$+BP',
    [`${prefix}medianEstimateUsdPlusBp`]: titlePrefix + 'MEDIAN EST$+BP',
    [`${prefix}lowEstimateUsdPlusBp`]: titlePrefix + 'LOW EST$+BP',
    [`${prefix}estimateDiapasonUsd`]: titlePrefix + 'Estimate price (USD)',
  };
};

const LotsListPage = {
  imageFile: 'Thumbnail',
  seller: 'Seller',
  privateSaleDate: 'Created Date',
  saleNumber: 'Sale #',
  lotNumber: 'Lot #',
  isSoldPriceUpdated: 'New Price',
  estimatePriceRaw: 'Estimate (raw)',
  estimatePriceStart: 'Estimate Low',
  estimatePriceEnd: 'Estimate High',
  soldPriceRaw: 'Sold Price (raw)',
  soldPriceUnitRaw: 'Currency (raw)',
  soldPriceUsd: 'Sold Price, $',
  sourceType: 'Source',
  year: 'Year',
  signed: 'Signed* (calculated)',
  surface: 'Surface* (calculated)',
  substrate: 'Substrate* (calculated)',
  height: 'Height* (calculated)',
  width: 'Width* (calculated)',
  depth: 'Depth* (calculated)',
  // @todo 'Estimate Low (raw currency)',
  // @todo 'Estimate High (raw currency)',
  estimatePriceStartUsd: 'Estimate Low (USD)',
  estimatePriceEndUsd: 'Estimate High (USD)',
  // @todo 'Estimate unit (raw)',
  // @todo 'Sold unit (raw)',
  condition: 'Condition',
  provenance: 'Provenance',
  exhibition: 'Exhibition',
  literature: 'Literature',

  catalogRaisonne: 'Catalog Raisonne',
  createdDate: 'Created',
  updatedDate: 'Updated',
  latestRtv: 'WEB RTV', // (discuss ways to display current)
  lastUpdatedByDataTeam: 'Last Updated (by Data Team member)',
  lotUrls: 'Item URL',
  auctionTitle: 'Auction Title',
  auctionLocation: 'Auction Location',
  auctionSaleUrl: 'Auction URL',
  auctionSaleDate: 'Auction Date',
  auctionStartDate: 'Start',
  // auctionEndDate: 'End',
  auctionDate: 'End',
  auctionSaleTime: 'Auction Time',
  auctionStartTime: 'Start',
  auctionEndTime: 'End',
  wfAcceptance: 'State', // (New, Draft, Valid, Ignored, Accepted)
  category: 'Category',
  currency: 'Currency',
  itemUrl: 'URL',
  artistIdWfAcceptance: 'Artist Status',
  artIdWfAcceptance: 'Art Status',
  'placeholder.auctionTitle': 'Auction Title',
  'placeholder.auctionLocation': 'Auction Location',
  'placeholder.auctionSaleUrl': 'Auction Url',
  'placeholder.auctionStartDate': 'MM/DD/YYYY',
  'placeholder.auctionEndDate': 'MM/DD/YYYY',
  'placeholder.auctionStartTime': 'HH:MM',
  'placeholder.auctionEndTime': 'HH:MM',
  'placeholder.estimatePriceStart': '0.00',
  'placeholder.estimatePriceEnd': '0.00',
  'placeholder.isSoldPriceUpdated': 'Has new sold price?',
  alertAbv: 'Alert ABV',
  artistShowRtv: 'ABV Artist',
  LOT_CAR: 'LOT CAR',
  ...artworkCARLots(null),
  ...artworkCARLots(0),
  ...artworkCARLots(1),
  ...artworkCARLots(2),
  ...artworkCARLots(3),
  ...artworkCARLots(4),
  ...artworkCARLots(5),
};

const AwPageProvenances = {
  'provenances.uuid': '',
  'provenances.art': '',
  'provenances.owner': 'Owner',
  'provenances.dateFrom': '',
  'provenances.dateTo': '',
  'provenances.country': '',
  'provenances.city': ' ',
  'placeholder.provenances.uuid': ' ',
  'placeholder.provenances.art': ' ',
  'placeholder.provenances.owner': 'Specify provenance',
  'placeholder.provenances.yearFrom': 'YYYY',
  'placeholder.provenances.yearTo': 'YYYY',
  'placeholder.provenances.country': 'Type or select country',
  'placeholder.provenances.city': 'City, State / Province',
};

const cluster = {
  url: 'Image',
  cluster: 'Cluster',
  saleDate: 'Sale Date',
  baseSoldPrice: 'Sold',
  cpiNormalBaseSoldPrice: 'Sold$(CAR)',
  area: 'Area',
  ppsi: 'PPSI',
  mediumsSurfaces: 'Surface',
  mediumsSubstrates: 'Substrate',
  methodInfo: 'Intersections',
  auctionDate: 'Auction Date',
};

const clustersList = {
  auctionDate: 'Auction Date',
  baseSoldPrice: 'Last Sold',
  cpiNormalBaseSoldPrice: 'Car Sold',
  primarySurfaceRank: 'Surf. Rank',
  primarySubstrateRank: 'Sub. Rank',
  area: 'Area',
  ppsi: 'PPSI',
};

const spiderRunner = {
  id: 'ID',
  addRunner: 'Add Runner',
  runnerList: 'Runners',
  runners: 'Runners',
  artifactName: '',
  artifactPackagesPath: '',
  artifactPythonVersion: '',
  dateStatusUpdate: '',
  domain: 'Domain',
  scrapydHost: '',
  spiderName: 'Name',
  startDate: '',
  type: 'Type',
  useCrawlItem: '',
  version: 'Version',
  versionValue: 'Version',
  wfAcceptance: 'State',
  createdDate: 'Created',
  updatedDate: 'Updated',
};

const spiderItem = {
  id: 'ID',
  itemList: 'Items',
  items: 'Items',
  type: 'Type',
  itemUrl: 'URL',
  itemListCount: 'Quantity',
  createdDate: 'Created',
  updatedDate: 'Updated',
};

const session = {
  id: 'ID',
  sessionList: 'Sessions',
  sessions: 'Sessions',
  jobId: 'Job ID',
  spiderName: 'Spider Name',
  pendingDate: 'Pending',
  closeDate: 'Close Date',
  createdCountLots: 'Created',
  openDate: 'Open Date',
  totalCountLots: 'Total',
  updatedCountLots: 'Updated',
  wfAcceptance: 'State',
};
const portfolio = {
  portfolioId: "Portfolio ID",
  enterprise: 'Enterprise',
  userName: "User Name",
  portfolioTitle: "Portfolio Name",
  id: "Art Asset ID",
  artworkTitle: "Artwork Title",
  artistName: "Artist Name",
  acquisitionDate: "Acquisition Date",
  acquisitionValue: "Acquisition Value",
  projectedValue: "Projected Value",
  ltmGain: "LTM $ Gain/Loss",
  ltmGainPercentage: "LTM % Gain/Loss",
  totalGain: "Total $ Gain/Loss",
  totalGainPercentage: "Total % Gain/Loss",
  status: "Status",
  dateCreated: "Date Created",
  dateUpdated:"Date Updated",
  // userId: "User Id",
};

const scrapped = {
  scrappedList: 'Spider Arts',
  scrappeds: 'Spider Arts',
  id: 'ID',
  art: 'Art',
  artist: 'Artist',
  auction: 'Auction',
  auctionLot: 'Auction Lot',
  exceptionText: 'Exception',
  isCorrect: 'Correct',
  spiderItem: 'Spider Item',
  itemUrl: 'Item URL',
  item: 'Item',
  createdDate: 'Created',
  updatedDate: 'Updated',
};

const enterprise = {
  addEnterprise: 'Add Enterprise',
  id: 'ID',
  enterpriseName: 'Name',
  abvPurchases: 'ABV Purchases',
  active: 'Status',
  status: 'Status',
  includedAbv: 'Included ABV',
  limitedAbv: 'Limited ABV',
  expirationDate: 'Expiration Date',

  enterpriseWebappClientLogo: 'Client Logo',

  artworkReportCoverPageLogo: 'Cover page logo',
  artworkReportTitle: 'Report Title',
  artworkReportSubtitle: 'Report Subtitle',
  artworkReportPrimaryColor1: 'Primary color #1:',
  artworkReportSecondaryColor1: 'Secondary color #1:',
  artworkReportSecondaryColor2: 'Secondary color #2:',
  artworkReportTextColor: 'Cover page text color',
  artworkArtBnkLogo: 'ARTBnk Logo',
  artworkArtBnkCoverPageLogo:  'ARTDAI Logo-Cover Page',
  artworkArtBnkLastPageLogo: 'ARTDAI Logo-Last Page',
  artworkReportHeaderLogo: 'Report header logo',

  artistReportCoverPageLogo: 'Cover page logo',
  artistReportTitle: 'Report Title',
  artistReportSubtitle: 'Report Subtitle',
  artistReportPrimaryColor1: 'Primary color #1:',
  artistReportSecondaryColor1: 'Secondary color #1:',
  artistReportSecondaryColor2: 'Secondary color #2:',
  artistReportTextColor: 'Cover page text color',
  artistArtBnkLogo: 'ARTBnk Logo',
  artistArtBnkCoverPageLogo:  'ARTDAI Logo-Cover Page',
  artistArtBnkLastPageLogo: 'ARTDAI Logo-Last Page',
  artistReportHeaderLogo: 'Report header logo',

  portfolioClientLogoUrl: "Cover page logo",
  portfolioReportTitle: "Report Title",
  portfolioReportSubtitle: "Report Subtitle",
  portfolioPrimaryColor: "Primary color #1:",
  portfolioSecondaryColor1: "Secondary color #1:",
  portfolioSecondaryColor2: "Secondary color #2:",
  portfolioCoverPageTextColor: "Cover page text color",
  portfolioArtdaiLogo: 'ARTBnk Logo',
  portfolioArtdaiLogoColor: "ARTDAI Logo-Cover Page",
  portfolioArtdaiLastPageLogoColor: "ARTDAI Logo-Last Page",
  portfolioReportHeaderLogoUrl: "Report header logo",
};

const enterpriseFilters = {
  ...enterprise,
  active: 'Status',
};

const portfolioFilter = {
  ...portfolio,
  id: 'Art Asset ID',
};

export default {
  // actions
  // 'bulk.accept': 'Accept {amount, plural, zero {} other { ({amount})}}',
  // 'bulk.merge': 'Merge {amount, plural, zero {} other { ({amount})}}',
  // 'bulk.update': 'Update {amount, plural, zero {} other { ({amount})}}',
  // 'bulk.draft': 'Draft {amount, plural, zero {} other { ({amount})}}',
  // 'bulk.validate': 'Validate {amount, plural, zero {} other { ({amount})}}',
  // 'bulk.ignore': 'Ignore {amount, plural, zero {} other { ({amount})}}',
  // 'bulk.restore': 'Restore {amount, plural, zero {} other { ({amount})}}',
  // 'bulk.remove': 'Delete {amount, plural, zero {} other { ({amount})}}',
  // 'bulk.display': 'Display {amount, plural, zero {} other { ({amount})}}',
  'bulk.accept': 'Accept',
  'bulk.merge': 'Merge',
  'bulk.update': 'Update',
  'bulk.draft': 'Draft',
  'bulk.validate': 'Validate',
  'bulk.ignore': 'Ignore',
  'bulk.restore': 'Restore',
  'bulk.remove': 'Delete',
  'bulk.display': 'Display',
  accept: 'Accept',
  validate: 'Validate',
  merge: 'Merge',
  delete: 'Delete',
  showAos: 'Show AOs',
  'Art Asset ID': 'Art Asset ID',
  showRepeatSalePairs: 'Show Repeat Sale Pairs',
  save: 'Save',
  cancel: 'Cancel',
  draft: 'Draft',
  lock: 'Lock',
  restore: 'Restore',
  unlock: 'Unlock',
  enable: 'Enable',
  disable: 'Disable',
  start: 'Start',
  stop: 'Stop',
  pushAbvSuccess: 'ABV successfully Updated',
  pushAbvFailure: 'ABV push failed, please try again',
  updateArtItem: 'Update',
  setCorrect: 'Correct',
  setIncorrect: 'Incorrect',
  pushUpdate: 'Push Update',
  pushUpdateSuccess: 'ABV was successfully updated',
  pushUpdateFailure: 'ABV was not updated',
  actionComplete: 'Operation completed',
  actionPartialComplete:
    'Operation completed with {errors} {errors, plural, one {error} other {errors} }.',
  objectRemoved: 'Object removed',
  objectNotRemoved: 'Object not Removed',
  objectRestored: 'Object restored',
  objectNotRestored: 'Object not restored',
  showWebRtv: 'Show Web RTV',
  hideWebRtv: 'Hide Web RTV',
  updateAeAbv: 'Update AE ABV',
  syncWebAbv: 'Sync Web ABV',
  deactivate: 'Deactivate',
  activate: 'Activate',
  deleted: 'Deleted',
  active: 'Active',
  updated: 'Updated', 
  addAo: 'Add AO',
  addLot: 'Add Lot',
  runRtv: 'Run ABV',
  remove: 'Delete',
  ignore: 'Ignore',
  showSimilar: 'Show Similar',
  moreOptions: 'more options',
  state: 'State',
  mergeWithId: 'Merged With',
  nfError: '404 error',
  nfErrorMessage: 'Sorry, the page you were looking for doesn’t exist',
  noResults: 'No results found with this filter combination. ',
  checkCriteria: 'Try different criteria',
  enableOutliers: 'Enable Outliers',
  disableOutliers: 'Disable Outliers',
  toggleSorting: 'toggle sorting',
  removeFromSorting: 'remove from Sorting',
  totalItems: 'Found <strong>{value}</strong> items',
  // menu
  resetSettings: 'Reset settings',
  myAcc: 'My account',
  logOut: 'Log out',
  artexpert: 'ARTExpert',
  webapp: 'WebApp',
  artists: 'Artists',
  arts: 'My Collection',
  spiders: 'Spiders',
  indexes: 'indexes',
  published: 'published',
  X001: 'X001',
  X002: 'X002',
  X003: 'X003',
  X004: 'X004',
  portfolioData: 'Portfolio Data',
  portfolioList: 'Portfolio User Data',
  junkCharacters: 'Junk Characters',
  runners: 'Runners',
  sessions: 'Sessions',
  items: 'Items',
  auctionList: 'Auctions',
  saleList: 'Auction Sales',
  subjectList: 'Subjects',
  mediumList: '3D Mediums',
  surfaceList: 'Surfaces',
  substrateList: 'Substrates',
  customerList: 'Customers',
  enterpriseList: 'Enterprises',
  considerationList: 'WatchList',
  artList: 'My Collection',
  smctagList: 'Tags',
  addAuction: 'Add auction',
  addSubject: 'Add subject',
  addMedium: 'Add 3D medium',
  addSmcTag: 'Add Tag',
  addSurface: 'Add surface',
  addSubstrate: 'Add substrate',
  addSale: 'Add Sale',
  auctionLots: 'Auction Lots',
  alerts: 'Alerts',
  considerations: 'Consideration',
  track: 'WatchList',
  users: 'Users',
  customers: 'Customers',
  enterprises: 'Enterprises',
  salesHistory: 'Sales History',
  auctions: 'Auctions',
  auctionSales: 'Auction Sales',
  subjects: 'Subjects',
  subject: 'Subject',
  deletedSubjects: 'Deleted Subjects',
  substrates: 'Substrates',
  surfaces: 'Surfaces',
  primarySurfaceRank: 'Surface Rank',
  primarySubstrateRank: 'Substrate Rank',
  Enterprise: 'Enterprise',
  deactivated: 'Deactivated',
  clientPersonalizationSpace: 'CLient Personalization Space',
  enterpriseWebApp: 'Enterprise WebApp',
  artistReport: 'Artist  Report',
  artworkReport: 'Artwork Report',
  portfolioReport: 'Portfolio Report',
  landingPageBanner: 'Landing page banner',
  // common
  id: 'ID',
  createdDate: 'Created',
  acceptedDate: 'Accepted',
  updatedDate: 'Updated',
  ifAvailable: 'If available',
  ifOnline: 'If Online',
  ao: 'AOs',
  zero: '1',
  min: 'min',
  max: 'max',
  itemNotCreated: 'Item was not created',
  lotAdded: 'Lot was added',
  lotNotAdded: 'Lot was not added',
  lotRemoved: 'Lot was removed',
  lotNotRemoved: 'Lot was not removed',
  artistRemoved: 'Artist was removed',
  artistNotRemoved: 'Artist was not removed',
  artworkNotRemoved: 'Artwork was not Removed',
  artworkRemoved: 'Artwork was removed',
  'Token has expired, please auth again':
    'Token has expired, please auth again',
  // statuses
  all: 'all',
  new: 'new',
  valid: 'valid',
  accepted: 'accepted',
  display: 'Display',
  update: 'Update',
  // artist page | artist list page | artist
  artistCreated: 'Artist Created',
  fullName: 'Name',
  namePrefix: 'Prefix',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  nameSuffix: 'Suffix',
  residences: 'Countries',
  birthDate: 'Birth',
  deathDate: 'Death',
  smcTag: 'Tag',
  carTag: 'Tag',
  carValueArtist: 'CAR',
  ppsi: 'PPSI',
  PDFGenerator: 'PDF Generator',
  currentCarValue: 'Current CAR',
  artImageUrl: 'Image URL',
  firstAcceptedSoldPriceUsd: '1st Accepted Auction Price',
  firstAcceptedLotDate: '1st Accepted Auction Date',
  lowCarAbv: 'Low ABV',
  middleCarAbv: 'Middle ABV',
  highCarAbv: 'High ABV',
  lowSoldPriceUsdWithCar: 'Low CAR $',
  middleSoldPriceUsdWithCar: 'Middle CAR $',
  highSoldPriceUsdWithCar: 'High CAR $',
  firstAuctionEstimateUsd: '1st auction estimate (USD)',
  firstAuctionPriceUsd: '1st auction price (USD)',
  firstAuctionDate: '1st auction date',
  firstAuctionHouse: '1st auction house',
  lastExhibition: 'Exhibition',
  lastLiterature: 'Literature',
  lastProvenance: 'Provenance',
  lifeYears: 'Life Years',
  banner343x120: '343 x 120 px',
  banner720x120: '720 x 120 px',
  banner970x120: '970 x 120 px',
  bannerDisplayDate: 'Displaying Dates',
  bannerDisplayDateFrom: 'From',
  bannerDisplayDateTo: 'To',
  bannerUrl: 'Website Url',
  completeness: 'Completeness',
  sourceType: 'Source',
  biography: 'Biography',
  notes: 'Information',
  countKnownArts: 'AO',
  countScrapedArts: 'Scraped AO',
  countTradedArtsInDb: 'Auction Lot',
  countSoldArtsInDb: 'Sold',
  customersAoCount: 'User AO',
  mergeWith: 'Merged with',
  rtvCount: 'RTV AO',
  showRtv: 'RTV',
  basicInfo: 'Basic info',
  index: 'Index',
  statistics: 'Statistics',
  advertisement: 'Advertisement',
  mergedArtists: 'Merged Artists',
  emptyFields: 'Empty',
  artItems: 'Art Items',
  relations: 'Relations',
  automatedCARCalculation: 'Automated CAR calculation',
  // lot
  lots: 'Lots',
  artworkTitle: 'Artwork',
  artIdId: 'AO ID',
  'filter.lot.placeholder.category': 'category',
  'filter.lot.label.category': 'category',
  'filter.lot.label.hammerPrice':"Hammer Price",
  // artwork
  lotCreated: 'Lot was created',
  artworkMerged: 'Artwork was merged',
  artworkNotMerged: 'Error. Artwork not merged',
  createdDateYear: 'Year created',
  backTo: 'Back to',
  noSimilar: 'Similar images not found or already merged',
  currentAO: 'Current AO',
  similarAOs: 'Similar AO`s',
  similarTo: 'Similar to ',
  outliers: 'Outliers',
  categoryId: 'Category',
  useCarPrice: 'CAR Price',
  ...AwPageLotsSection,
  ...AwPageProvenances,
  'placeholder.literature.name': placeHoldersData.literature,
  'placeholder.exhibitions.name': placeHoldersData.exhibitions,
  'placeholder.exhibition.name': placeHoldersData.exhibition,
  // Artwork
  conceptionDate: 'Conception Date',
  conceptionDateFrom: 'YYYY',
  conceptionDateTo: 'YYYY',
  inscription: 'Inscription',
  studio: 'Studio',
  authenticityLetter: 'Letter of Authenticity',
  foundry: 'Foundry',
  stamps: 'Stamp',
  edition: 'Edition',
  plate: 'Plate',
  printer: 'Printer',
  publisher: 'Publisher',
  imageSizeRaw: 'Size',
  sheetSizeRaw: 'Sheet size',
  details: 'Details',
  numberedNo: 'no.',
  numberedOf: 'of',
  editionSize: 'edition size',
  artistNotAccepted: 'Artist not Accepted',
  artworkNotAccepted: 'Unacceptable AO status',
  noLots: 'This AO has no Lots',
  soldPriceIsRequired: 'Sold price is required for Display',
  currencyIsRequired: 'Currency is required for Display',
  someRequiredFieldsAreEmpty: 'Some required fields are empty',
  invalidForm: 'Form is not valid',
  exhibitions: 'Exhibition',
  exhibition: 'Exhibition',
  literature: 'Literature',
  addArtwork: 'Add Art',
  artworks: 'Arts',
  title: 'Title',
  defaultImageUrl: 'Image',
  defaultArtImageId: 'Default Image',
  defaultArtImage: 'Image',
  additionalInfo: 'Additional Info',
  auctionLot: 'Auction Lot',
  rtv: 'ABV',
  artist: 'Artist',
  isSignature: 'Signed',
  size: 'Calculated Size',
  physicalSize: 'Calculated size',
  imageSize: 'Size',
  customer: 'Customer',
  sheetSize: 'Sheet size*',
  category: 'Category',
  medium: 'Medium',
  mediumRaw: 'Medium Raw',
  medium3d: '3D Mediums',
  physicalSizeWidth: 'Width',
  physicalSizeHeight: 'Height',
  physicalSizeDepth: 'Depth',
  area: 'Area',
  aoId: 'AO ID',
  artistId: 'Artist',
  artistIdId: 'Artist ID',
  artistIdWfAcceptance: 'Artist Status',
  artIdWfAcceptance: 'Art Status',
  catalogRaisonne: 'Catalog Raisonne',
  surfaceValues: 'Surface Child',
  substrateValues: 'Substrate Child',
  signature: 'Signature Raw',
  year: 'Year',
  itemUrls: 'Item URL',
  yearCreatedFrom: 'Year From',
  yearCreatedTo: 'Year To',
  countOfLots: 'Lots Count',
  hasSimilarImages: 'Similar AOs',
  hasRepeatSales: 'Repeat Sale',
  searchCount: 'Search Count',
  alertsCount: 'Alert Count',
  artistShowRtv: 'ABV Artist',
  physicalSizeRaw: 'Size Raw',
  physicalSizeUnits: 'Unit',
  provenances: 'Provenance',
  spLastUsd: 'Sold Price, $',
  epLastUsd: 'Estimate, $',
  epStartLastUsd: 'Estimate Low, $',
  epEndLastUsd: 'Estimate High, $',
  lastLotCurrency: 'Currency',
  auctionHouseLast: 'Auction',
  lastSaleNumber: 'Sale #',
  lastLotNumber: 'Lot #',
  auctionDateLast: 'Auction Date',
  spLast: 'Sold Price',
  estimatePriceLast: 'Estimate',
  estimatePriceStartLast: 'Estimate Low',
  estimatePriceEndLast: 'Estimate High',
  aeRtv: 'AE ABV',
  defaultRtv: 'Default ABV',
  rtvLast: 'WEB ABV',
  rtvDate: 'WEB ABV Updated',
  alertAbv: 'Alert ABV',
  alertHighAbv: 'Alert High ABV',
  alertMiddleAbv: 'Alert Middle ABV',
  alertLowAbv: 'Alert Low ABV',
  highEstimate: 'High Est + BP',
  middleEstimate: 'Median Est + BP',
  lowEstimate: 'Low Est + BP',
  clusterNumber: 'Cluster #',
  costPerSquareWithCar: 'PPSI',
  lastAcceptedAndDisplaySoldPriceUsd: 'Last Sold Price',
  lastAcceptedAndDisplayLotDate: 'Last Sold Date',
  lastAcceptedAndDisplaySoldPriceUsdWithCar: 'Last Sold$(CAR)',
  aoCar: 'Custom AO CAR',
  abvLastSoldPrice: 'Sold$(CAR)',
  usernameLastUpdate: 'Data Team',
  itemSelected: 'item selected',
  itemsSelected: 'items selected',
  noRtvData: 'No data to render RTV',
  hammerPrice:'Hammer Price',
  ...LotsListPage,
  // loginPage
  username: 'Username',
  password: 'Password',
  email: 'Email',
  logIn: 'Log In',
  done: 'Done!',
  forgotText:
    'Enter the email address and we will send password reset instructions',
  forgotSuccessText:
    'You should receive them shortly, if an account exists with the email you entered.',
  sendAgain: 'Send Again',
  resetPassword: 'Reset password',
  forgotPassword: 'Forgot Password?',
  returnToLogIn: 'Return to Log In',
  saveChanges: 'Save Changes',
  lotUpdated: 'Lot changes saved',
  lotNotUpdated: 'Error. Lot changes not saved',
  enterNewPass: 'Please enter your new password.',
  passwordHelper: 'We recommend you create a unique password.',
  //validation
  wrongEmail: 'Incorrect email address',
  dimensionError: '<strong>300x300px</strong> min.',
  bannerDimensionError: 'Wrong image size',
  invalidBannerDate: 'Should be Same or After Date From',
  required: '<strong>{name}</strong> is required',
  positiveNumber: 'Should be positive Number',
  invalidUrl: '<strong>URL</strong> is invalid',
  defaultImageRequired: '<strong>Image</strong> is required',
  lifeRange: 'Not in artist work period ({birthDate} to {deathDate})',
  provenanceYearTo: 'Should be higher than {yearFrom}',
  passwordLength: 'Password should be 8 characters or more',
  wrongCredentials: 'User name or password are incorrect',
  customMessage: '{message}',
  invalidYear: 'Invalid year',
  invalidDeathDate: 'Lowest value first',
  ...placeHolders,
  // columns select popup
  default: 'Default',
  AO: 'AO',
  ADDITIONAL: 'ADDITIONAL',
  ABV: 'ABV',
  EDITIONS: 'EDITIONS',
  additional: 'Additional',
  findArtist: 'Enter new value to find another artist',
  noArtistsToMerge: 'No artists found, please change search string',
  // ArtistsPage
  addArtist: 'Add Artist',
  // Image Loader
  upload: 'Upload',
  upload_logo: 'Upload Logo',
  textLeft: 'Drag files or',
  textRight: 'to upload',
  browse: 'browse',
  uploading: 'Uploading image ({index} of {total})',
  // Filter Component
  fcShow: 'Show Filter',
  fcHide: 'Hide Filter',
  apply: 'Apply',
  reset: 'Reset',
  more: '+ More',
  guarantee: 'Guaranty',
  lastGuarantee: 'Guaranty',
  privateSale: 'Private Sale',
  notPrivateSale: 'Not Private Sale',
  applyToFilters: 'Apply to Filters',
  applyToColumns: 'Apply to Columns',
  showDeleted: 'Show Deleted',
  showNotDeleted: 'Show Not Deleted',
  deletedPrefix: 'Deleted ',
  invalidPrefix: 'Invalid ',
  showInvalid: 'Show Invalid',
  showNotInvalid: 'Show not Invalid',
  lotPriceUpdated: 'Lot price was updated',
  lotPriceNotUpdated: 'Lot price was not updated',
  abvQualityBenchmark: 'ABV, %',
  lastAlertAbv: 'Alert ABV',
  abvMetricsManifold: 'Image ABV',
  abvMetricsMediums: 'Medium ABV',
  abvMetricsArea: 'Area ABV',
  abvMetricsPpsi: 'PPSI Filter',
  ...withContext('artist', 'placeholder', artistFilterPlaceholders),
  ...withContext('artist', 'label', artistFilterLabels),
  ...withContext('artwork', 'placeholder', artworkFilterPlaceholders),
  ...withContext('artwork', 'label', artworkFilterLabels),
  ...withContext('consideration', 'placeholder', artworkFilterPlaceholders),
  ...withContext('consideration', 'label', artworkFilterLabels),
  ...withContext('art', 'placeholder', artworkFilterPlaceholders),
  ...withContext('art', 'label', artworkFilterLabels),
  ...withContext('lot', 'label', lotFilterLabels),
  ...withContext('lot', 'placeholder', lotFilterPlaceholders),
  ...popups,
  ...addPrefix(history, 'history'),
  ...addPrefix(lotPrices, 'lotPrices'),
  'selected.items.residences': '{amount} countries selected',
  'selected.items.carTag': '{amount} Tags selected',
  // artwork page filters
  'placeholder.outliers': 'Outliers',
  'placeholder.carTag': 'Tag',
  'placeholder.smcTag': 'Tag',
  'placeholder.currentCarValue': 'Current CAR',
  ...addPrefix(popupActions, 'popupAction'),
  ...auction,
  ...addPrefix(auction, 'placeholder'),
  ...addPrefix(auction, 'filter', 'auction', 'label'),
  ...addPrefix(auction, 'filter', 'auction', 'placeholder'),
  ...enterprise,
  ...addPrefix(enterprise, 'enterprise'),
  ...addPrefix(enterprise, 'placeholder'),
  ...addPrefix(enterpriseFilters, 'filter', 'enterprise', 'label'),
  ...addPrefix(enterpriseFilters, 'filter', 'enterprise', 'placeholder'),
  ...medium,
  ...addPrefix(medium, 'placeholder'),
  ...addPrefix(medium, 'filter', 'medium', 'label'),
  ...addPrefix(medium, 'filter', 'medium', 'placeholder'),
  ...substrate,
  ...addPrefix(substrate, 'placeholder'),
  ...addPrefix(substrate, 'filter', 'substrate', 'label'),
  ...addPrefix(substrate, 'filter', 'substrate', 'placeholder'),
  ...addPrefix(substrate, 'filter', 'surface', 'label'),
  ...addPrefix(substrate, 'filter', 'surface', 'placeholder'),
  ...sale,
  ...addPrefix(sale, 'placeholder'),
  ...addPrefix(sale, 'filter', 'sale', 'label'),
  ...addPrefix(sale, 'filter', 'sale', 'placeholder'),
  ...subject,
  ...addPrefix(subject, 'placeholder'),
  ...addPrefix(subject, 'filter', 'subject', 'label'),
  ...addPrefix(subject, 'filter', 'subject', 'placeholder'),
  ...spiderRunner,
  ...addPrefix(spiderRunner, 'placeholder'),
  ...addPrefix(spiderRunner, 'filter', 'runner', 'label'),
  ...addPrefix(spiderRunner, 'filter', 'runner', 'placeholder'),
  ...spiderItem,
  ...addPrefix(spiderItem, 'placeholder'),
  ...addPrefix(spiderItem, 'filter', 'item', 'label'),
  ...addPrefix(spiderItem, 'filter', 'item', 'placeholder'),
  ...coupon,
  ...session,
  ...addPrefix(session, 'placeholder'),
  ...addPrefix(session, 'filter', 'session', 'label'),
  ...addPrefix(session, 'filter', 'session', 'placeholder'),
  ...portfolio,
  ...addPrefix(portfolio, 'placeholder'),
  ...addPrefix(portfolioFilter, 'filter', 'portfolio', 'label'),
  ...addPrefix(portfolioFilter, 'filter', 'portfolio', 'placeholder'),
  ...scrapped,
  ...addPrefix(scrapped, 'placeholder'),
  ...addPrefix(scrapped, 'filter', 'scrapped', 'label'),
  ...addPrefix(scrapped, 'filter', 'scrapped', 'placeholder'),
  ...addPrefix(coupon, 'placeholder'),
  ...addPrefix(coupon, 'filter', 'coupon', 'label'),
  ...addPrefix(coupon, 'filter', 'coupon', 'placeholder'),
  ...customer,
  ...addPrefix(customerFilters, 'filter', 'customer', 'label'),
  ...addPrefix(customer, 'filter', 'customer', 'placeholder'),
  ...cluster,
  ...clustersList,
  ...addPrefix(cluster, 'filter', 'artwork', 'label'),
  ...addPrefix(cluster, 'filter', 'artwork', 'placeholder'),
  ...Alert,
  ...addPrefix(alertFilters, 'filter', 'alert', 'placeholder'),
  ...addPrefix(alertFilters, 'filter', 'alert', 'label'),
  ...purchase,
  ...addPrefix(purchase, 'filter', 'purchase', 'placeholder'),
  ...addPrefix(purchase, 'filter', 'purchase', 'label'),
  ...smcTags,
  ...addPrefix(smcTags, 'placeholder'),
  ...addPrefix(smcTags, 'filter', 'smcTags', 'label'),
  ...addPrefix(smcTags, 'filter', 'smcTags', 'placeholder'),
  // modal window
  'menuModalNote.select.artwork': 'Select Artwork',
  mnuSetDefault: 'Make default',
  mnuCrop: 'Crop image',
  mnuDelete: 'Delete image',
  cropImpossible: 'Image is too small. Impossible to crop.',
  bulkAction: 'Bulk action: ',
  change_bulk: 'Update',
  ...trialPage,
  ...addPrefix(trialPage, 'placeholder'),
  // junk characters page
  junkSymbol: 'Junk Symbol',
  'placeholder.junkSymbol': 'Junk Symbol',
  correctLetter: 'Correct Letter',
  'placeholder.correctLetter': 'Correct Letter',
  addJunkCharacter: 'add new',
  'popup.addJunkCharacter': 'Add Junk Character',
};
