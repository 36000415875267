import {useCallback} from 'react'
import Api from 'domain/api.js'

export const useLoginSubmit=({
    key,
    dataMapper=v => v,
    callBack,
    errorsMapper=v => v,
    requestProps={},
}) => {
    const onSubmit=useCallback(async (formData, form, resolve) => {
        if (form.getState().valid) {
            try {
                const { data } = await Api[key]({data: dataMapper(formData), ...requestProps})
                if (callBack && data.access) callBack({ token: data.access, refreshToken:data.refresh });
            } catch (e) {
                resolve(errorsMapper(e.response))
            }
        }
    }, [key, callBack, errorsMapper, dataMapper, requestProps])

    return {onSubmit}
}