import injectSheet from 'lib/sheet';
import PropTypes from "prop-types";
import DateFieldInput from 'components/form/fields/DateField';
import cx from 'classnames';
import React from 'react';
import colors from 'theme/Colors';
import { DEFAULT_FONTFAMILY } from 'theme/const';


const dateFieldSheetOrigin={
    wrapper: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
    },
    element: {
        height: 26,
        width: 106,
        color: colors.text,
        fontSize: 14,
        fontFamily: DEFAULT_FONTFAMILY,
        fontWeight: 400,
        border: `1px solid ${colors.fieldBorder}`,
        backgroundColor: colors.bg,
        paddingLeft: 10,
        boxSizing: 'border-box',
        
    },
    error: {
        borderColor: 'red !important',
    },
    warning: {
        backgroundColor: colors.warning,
    },
    err: {
        flex: '0 1 100%',
        display: 'flex',
    },
};

const dateFieldSheet={
    ...dateFieldSheetOrigin,
    wrapper: {
        ...dateFieldSheetOrigin.wrapper,
        display: 'inline-block',
        width: 60,
        marginRight: 16,
    },
};

const DateField = ({ classes, input, meta, placeholder, hasWarning, ...props }) => {
    return (
        <div className={cx(classes.wrapper)}>
            <DateFieldInput
                className={cx(classes.element, {
                    [classes.error]: meta.touched && meta.error,
                    [classes.warning]: hasWarning,
                })}
                placeholder={`${placeholder}`}
                {...props}
                {...{ ...input, onBlur: () => null }}
            />
        </div>
    );
};

DateField.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
    }).isRequired,
    validate: PropTypes.arrayOf(PropTypes.func),
    classes: PropTypes.shape({
        error: PropTypes.string.isRequired,
        wrapper: PropTypes.string.isRequired,
        element: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
    placeholder: PropTypes.string,
    hasWarning: PropTypes.bool,
};
const DateFieldPure=DateField;

export const DatePickerWithoutIntl=injectSheet({
    ...dateFieldSheet,
    wrapper: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        flex: '0 0 142px',
    },
})(DateFieldPure);

