
import React from "react";
import { Field } from 'redux-form';
import moment from "moment";
import { DatePickerWithoutIntl } from "./DatePicker";
import { decimalValueNormalize, formatWithCommas, normalizeInteger } from "../utils";
import { CustomCurrencyAutoComplete } from "./Select";
import rules from 'components/form/validation';
import PropTypes from "prop-types";
import { useBPRatesTableStyles } from "../styles";
import { RateInput } from "./RateInput";


export const BPRatesTable=({fields}) => {

  const classes=useBPRatesTableStyles();

  return (
    <div className={classes.outermostContainer}>
      <table className={classes.tableClass}>
        <thead>
          <tr className={classes.tableRowClass}>
            <th className={classes.tableHeading}>START DATE</th>
            <th className={classes.tableHeading}>END DATE</th>
            <th className={classes.tableHeading}>CURRENCY</th>
            <th className={classes.tableHeading}>RATE 1</th>
            <th className={classes.tableHeading}>THRESHOLD 1</th>
            <th className={classes.tableHeading}>RATE 2</th>
            <th className={classes.tableHeading}>THRESHOLD 2</th>
            <th className={classes.tableHeading}>RATE 3</th>
            <th className={classes.tableHeading}>THRESHOLD 3</th>
            <th className={classes.tableHeading}>RATE 4</th>
            <th className={classes.tableHeading}>THRESHOLD 4</th>
            <th className={classes.tableHeading}>RATE 5</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((bp, index) => (
            <tr className={classes.tableBodyRow} key={index}>
              <td>
                <Field
                  name={`${bp}.startDate`}
                  component={DatePickerWithoutIntl}
                  type="date"
                  placeholder="MM/DD/YYYY"
                  defaultValue={null}
                  format={(value) => value? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY'):null}
                  parse={(value) => value? moment(value, 'MM/DD/YYYY', true).format('YYYY-MM-DD'):null}
                  validate={[rules.required]}
                  onChange={(value) => {
                    fields.get(index).startDate=value;
                  }}

                />
              </td>
              <td>
                <Field
                  required={false}
                  name={`${bp}.endDate`}
                  component={DatePickerWithoutIntl}
                  type="date"
                  placeholder="MM/DD/YYYY"
                  format={(value) => value? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY'):null}
                  parse={(value) => value? moment(value, 'MM/DD/YYYY', true).format('YYYY-MM-DD'):null}
                  defaultValue={null}
                  minDate={fields.get(index).startDate? moment(fields.get(index).startDate, 'YYYY-MM-DD').toDate():null} // Min date for end date
                  onChange={(value) => {
                    fields.get(index).endDate=value;
                  }}
                />
              </td>
              <td className={classes.dataFieldWidth}>
                <Field
                  className={classes.currencyField}
                  name={`${bp}.currency`}
                  component={CustomCurrencyAutoComplete}
                  placeholder="Currency"
                  validate={[rules.required]}
                >
                </Field>
              </td>
              <td>
                <Field
                  className={classes.field}
                  name={`${bp}.rateOne`}
                  component={RateInput}
                  type="text"
                  normalize={decimalValueNormalize}
                  validate={[rules.required]}
                />
              </td>
              <td>
                <Field
                  className={classes.field}
                  name={`${bp}.thresholdOne`}
                  component="input"
                  type="text"
                  normalize={normalizeInteger}
                  format={formatWithCommas}
                />
              </td>
              <td>
                <Field
                  className={classes.field}
                  name={`${bp}.rateTwo`}
                  component={RateInput}
                  type="text"
                  normalize={decimalValueNormalize}
                />
              </td>
              <td>
                <Field
                  className={classes.field}
                  name={`${bp}.thresholdTwo`}
                  component="input"
                  type="text"
                  normalize={normalizeInteger}
                  format={formatWithCommas}
                />
              </td>
              <td>
                <Field
                  className={classes.field}
                  name={`${bp}.rateThree`}
                  component={RateInput}
                  type="text"
                  normalize={decimalValueNormalize}
                />
              </td>
              <td>
                <Field
                  className={classes.field}
                  name={`${bp}.thresholdThree`}
                  component="input"
                  type="text"
                  normalize={normalizeInteger}
                  format={formatWithCommas}
                />
              </td>
              <td>
                <Field
                  className={classes.field}
                  name={`${bp}.rateFour`}
                  component={RateInput}
                  type="text"
                  normalize={decimalValueNormalize}
                />
              </td>
              <td>
                <Field
                  className={classes.field}
                  name={`${bp}.thresholdFour`}
                  component="input"
                  type="text"
                  normalize={normalizeInteger}
                  format={formatWithCommas}
                />
              </td>
              <td>
                <Field
                  className={classes.field}
                  name={`${bp}.rateFive`}
                  component={RateInput}
                  type="text"
                  normalize={decimalValueNormalize}
                />
              </td>
              <td className={classes.removeBtnContainer}>
                <button className={classes.removeButton} type="button" onClick={() => fields.remove(index)}>
                  &#10005;
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={classes.addBtnContainer}>
        <button className={classes.addBtn} type="button" onClick={() => fields.push({})}>
          <span className={classes.icon}>
            +
          </span>
        </button>
      </div>
    </div>
  )
};

BPRatesTable.propTypes={
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
  }).isRequired,
};
