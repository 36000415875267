import {call, cancel, fork, put, takeEvery, takeLatest} from 'redux-saga/effects';

import {actions} from 'domain/auction';
import {editableFields as editableFieldsWithoutBPRates} from './index';
import Api, {callApi} from 'domain/api';
import {err404Action} from 'domain/ui';
import {initialize} from 'redux-form';
import {action} from 'lib/actions';
import {addNotification} from 'domain/env';
import {watchLoadHistory} from 'domain/history/saga';
import {push} from 'connected-react-router';
import {lnk} from 'lib/routes';

export const remove=action('REMOVE');

let lockTask=null;
const editableFields = [...editableFieldsWithoutBPRates,'bpRates'];
export const lock=action('LOCK_EDIT');

function convertRatesInArrayToFloat(arr) {
  return arr.map(obj => {
    const updatedObj={...obj};
    for (const key in updatedObj) {
      if (updatedObj.hasOwnProperty(key)&&key.startsWith("rate")) {
        updatedObj[key]=parseFloat(updatedObj[key]);
      }
    }
    return updatedObj;
  });
}


function* reInitForm(name, data) {
  yield put(initialize(name, data));
}


export const getEditableData=(editableFields, data) =>
  editableFields.reduce(
    (acc, name) => ({
      ...acc,
      ...(typeof name==='string'? {[name]: data[name]}:name(data)),
    }),
    {},
  );


function* watchLockAction(...formParams) {
  yield put(initialize(...formParams));
  if (lockTask) yield cancel(lockTask);
  lockTask=yield takeEvery(lock.type, reInitForm, ...formParams);
}

function* onDeleteAuction({modelName, id}) {
  const apiKey=`remove${modelName}`;
  try {
    yield callApi(Api[apiKey], {id});
    yield put(addNotification({title: 'objectRemoved', type: 'success'}));
    yield put(push(lnk(`${modelName.toLowerCase()}List`)));
  } catch (e) {
    yield put(addNotification({title: 'objectNotRemoved', type: 'remove'}));
    console.error(e);
  }
}


export function* loadAuctionData({
  modelName,
  actions,
  editableFields,
  id,
  initForm=true,
}) {
  const apiKey=`get${modelName}`;
  try {
    const {data}=yield callApi(Api[apiKey], {id});


    yield put({type: actions.fetchItemAction.success, payload: data});

    if (initForm) {
      yield call(
        watchLockAction,
        `${modelName.toLowerCase()}Form`,
        getEditableData(editableFields, data),
      );
    }
  } catch (e) {
    console.error(e);
    yield put(err404Action(true));
  }
}



export function* auctionNavigator(
  {
    modelName,
    actions,
    editableFields,
    loadItemData=loadAuctionData,
    selectors,
  },
  {params: {id}},
) {
  const params={modelName, actions, editableFields, id, selectors};
  yield fork(loadItemData, params);

  yield takeLatest(actions.updateItemAction.type, saveAuctionForm, params);
  yield takeEvery(remove.type, onDeleteAuction, {modelName, id});
  yield fork(watchLoadHistory);
}

export default function* navigator(args) {
  yield fork(auctionNavigator, {modelName: 'Auction', actions, editableFields}, args);
}

function* saveAuctionForm(
  {modelName, actions, editableFields, id},
  {payload, resolve=() => null},
) {
  const apiKey=`update${modelName}`;

  try {
    payload={...payload, 'bpRates': convertRatesInArrayToFloat(payload.bpRates)}
    const {data}=yield callApi(Api[apiKey], {id, data: payload});
    resolve();
    yield put({type: actions.updateItemAction.success, payload: data});
    yield call(
      watchLockAction,
      `${modelName.toLowerCase()}Form`,
      getEditableData(editableFields, data),
    );
    yield put(
      addNotification({
        title: 'Changes were successfully saved.',
        translate: false,
        type: 'success',
      }),
    );
  } catch (e) {
    resolve(e);
    yield put(
      addNotification({
        title: 'Changes were not saved.',
        translate: false,
        type: 'error',
      }),
    );
  }
}
