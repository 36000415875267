
import cx from 'classnames';
import React from 'react';
import { useBPRatesTableStyles } from '../styles';
import PropTypes from 'prop-types';


export const RateInput = ({input, meta, ...rest}) => {

    const classes = useBPRatesTableStyles();
    
    return (<div>
      <input  {...input} {...rest} className={cx({
        [classes.field]: true,
        [classes.error]: meta.touched&&meta.error
      })} />
    </div>)
  };
  
  
RateInput.propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
      onFocus: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool.isRequired,
      error: PropTypes.object,
    }).isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
};
